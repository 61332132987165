import { useContext } from 'react';

import { UserContext } from '@/contexts/UserContext';
import useFeatureFlags from '@/hooks/FeatureFlags/useFeatureFlags';
import { TFeatureFlags } from '@/types/generic';

import Section from '@/components/Section';

import FeatureFlagsRow from './FeatureFlagsRow';
import styles from './styles.module.css';

export default function WorkspaceFeatureFlags() {
  const { workspace } = useContext(UserContext);

  const { data } = useFeatureFlags({
    enabled: !!workspace?.id,
  });

  return (
    <Section title="Feature Flags">
      {data && data.length > 0 ? (
        data.map((flag: TFeatureFlags) => {
          return (
            <FeatureFlagsRow
              key={flag.code}
              code={flag.code ?? ''}
              description={flag.description}
              name={flag.name}
              isActive={flag.isActive}
              isDisabled={false}
            />
          );
        })
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.textContainer}>
            <h4>No feature flags available</h4>
            <p>Coming soon</p>
          </div>
        </div>
      )}
    </Section>
  );
}
