import { IconMoodSadDizzy } from '@tabler/icons-react';
import { t } from 'i18next';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';

import Button from '@/components/Button';

import styles from './styles.module.css';
import Modal from '../Modal';
import { ContentPages } from '../ModalUserProfile';

type Props = Pick<Parameters<typeof Modal>[0], 'isOpen' | 'onClose'>;

export default function ModalPastDueSubscription({ isOpen, onClose }: Props) {
  const { openModal } = useProfileModal();
  return (
    <Modal
      title={<IconMoodSadDizzy size={32} />}
      modalKey={'modal-past-due-subscription'}
      enableClose
      onClose={onClose}
      isOpen={isOpen}
      footer={
        <>
          <Button
            label={t('common:modals.pastDueSubscription.actionLabel')}
            onClick={() => {
              onClose?.();
              openModal(ContentPages.BILLING_INFO);
            }}
            size="medium"
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <h6 className={styles.modalTitle}>
        {t('common:modals.pastDueSubscription.title')}
      </h6>
      <p className={styles.modalDescription}>
        {t('common:modals.pastDueSubscription.description')}
      </p>
    </Modal>
  );
}
