import { useCallback, useContext, useEffect, useState } from 'react';

import Input from '@components/Input';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { UserContext } from '@/contexts/UserContext';
import { ROUTES } from '@/types/routes';

import Button from '@/components/Button';

import styles from '../SignInForm/styles.module.css';

export type FormData = { password: string; newPassword: string };

type Props = {
  /**
   * Function to be called when the first input change
   * @param string
   */
  onChangeFn: (data: string) => void;
  /**
   * Function to call when the form is submitted
   * @param data
   */
  onSubmitFn: (data: FormData) => void;
  /**
   * Loading state of the button
   */
  isLoading?: boolean;
};

export default function ResetPasswordForm({
  onSubmitFn,
  isLoading,
  onChangeFn,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'forms']);
  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onSubmit' });

  const currentPassword = watch('password');

  const onSubmit = useCallback(
    (data: FormData) => {
      if (data.newPassword !== data.password) {
        setError('newPassword', {
          message: t('forms:resetPassword.errors.mismatch') ?? '',
        });
        return;
      }
      onSubmitFn(data);
    },
    [onSubmitFn, setError, t],
  );

  useEffect(() => {
    const subscription = watch((value) => {
      onChangeFn(value?.password ?? '');
    });
    return () => subscription.unsubscribe();
  }, [onChangeFn, watch]);

  /**
   * Hide/show new password
   */
  const [showNewPassword, setShowNewPassword] = useState(false);
  const onToggleNewPassword = useCallback(
    (e: React.SyntheticEvent) => {
      e?.preventDefault();
      setShowNewPassword(!showNewPassword);
    },
    [showNewPassword, setShowNewPassword],
  );

  /**
   * Hide/show confirmation password
   */
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const onToggleConfirmPassword = useCallback(
    (e: React.SyntheticEvent) => {
      e?.preventDefault();
      setShowConfirmPassword(!showConfirmPassword);
    },
    [showConfirmPassword, setShowConfirmPassword],
  );

  const { isLoggedIn } = useContext(UserContext);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.ROOT, { preventScrollReset: false });
    }
  }, [isLoggedIn, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={styles.field}>
        <Controller
          rules={{
            required: {
              value: true,
              message: t('forms:resetPassword.password.required') || 'invalid',
            },
          }}
          control={control}
          name="password"
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <Input
                TrailingIcon={
                  <div aria-hidden="true" onClick={onToggleNewPassword}>
                    {showNewPassword ? (
                      <span className="icon-eye-closed text-lg leading-10"></span>
                    ) : (
                      <span className="icon-eye-opened text-lg leading-10"></span>
                    )}
                  </div>
                }
                onBlur={onBlur}
                size="large"
                name={name}
                value={value}
                type={showNewPassword ? 'text' : 'password'}
                label={t('forms:resetPassword.password.label') as string}
                placeholder={t('forms:resetPassword.password.placeholder')}
                onChange={onChange}
                error={errors?.password?.message as string}
              />
            );
          }}
        />
      </div>
      <div className={styles.field} style={{ marginBottom: 24 }}>
        <Controller
          rules={{
            required: {
              value: true,
              message: t('forms:resetPassword.password.required') || 'invalid',
            },
          }}
          control={control}
          name="newPassword"
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <Input
                onBlur={onBlur}
                TrailingIcon={
                  <div aria-hidden="true" onClick={onToggleConfirmPassword}>
                    {showConfirmPassword ? (
                      <span className="icon-eye-closed text-lg leading-10"></span>
                    ) : (
                      <span className="icon-eye-opened text-lg leading-10"></span>
                    )}
                  </div>
                }
                name={name}
                value={value}
                size="large"
                type={showConfirmPassword ? 'text' : 'password'}
                label={t('forms:resetPassword.newPassword.label') as string}
                placeholder={t('forms:resetPassword.newPassword.placeholder')}
                onChange={onChange}
                error={errors?.newPassword?.message as string}
              />
            );
          }}
        />
      </div>
      <div className={styles.button}>
        <Button
          label={t('forms:resetPassword.submit') ?? ''}
          disabled={!currentPassword}
          isLoading={isLoading}
          size="large"
        />
      </div>
    </form>
  );
}
