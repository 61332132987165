import { useContext } from 'react';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UserContext } from '@/contexts/UserContext';

import FreePlanCard from '@/components/PlanCard/FreePlanCard';
import ProPlanCard from '@/components/PlanCard/ProPlanCard';
import Section from '@/components/Section';
import UsageProgress from '@/components/UsageProgress';

import styles from './styles.module.css';

export default function ModalPlans() {
  const { usageInfoData: { overflow } = { overflow: false }, workspace: _ws } =
    useContext(UserContext);

  const { closeModal } = useProfileModal();

  return (
    <Section>
      <UsageProgress />
      <div className={styles.container}>
        <p className={styles.planCardDescription}>
          {overflow
            ? "You've reached your plan limits. Upgrade to unlock more active projects."
            : 'Upgrade to unlock more active projects'}
          .
        </p>
        <div className={styles.usageCardsContainer}>
          <FreePlanCard />
          <ProPlanCard onCheckoutOpen={closeModal} />
        </div>
      </div>
    </Section>
  );
}
