import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getTimelineProjectListWithResources } from '@/services/api/workspace/projects';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';

export const TIMELINE_PROJECT_QUERY_KEY = 'get-timeline-project';

export default function useTimelineProjectsPage({
  onError = noop,
  page = 1,
}: {
  page: number;
} & TSuccessErrorHook<TProjectListWithResources<TResourceItemList>[]>) {
  const { workspaceId } = useContext(UserContext);

  const { addNotification } = useContext(NotificationsContext);

  return useQuery({
    queryKey: [TIMELINE_PROJECT_QUERY_KEY, workspaceId, page],
    queryFn: () => {
      return getTimelineProjectListWithResources({
        workspaceId,
        page,
      });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: (error: TResponseError) => {
      onError(error);
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
