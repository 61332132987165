import { Fragment, useContext, useMemo } from 'react';

import Avatar from '@components/Avatar';
import DrawerCard from '@components/DrawerCard';
import ShowMore from '@components/ShowMore';
import ListSkeleton from '@components/skeleton/ListSkeleton';
import * as Tooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import { endOfWeek, format, startOfWeek, subDays } from 'date-fns';
import { t } from 'i18next';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useInsightsPartQuery from '@/hooks/insights/useInsightsPartQuery';
import { INSIGHTS_TYPE, WORKSPACE_MODE } from '@/types/enums';
import { TInsightsAvailable, TInsightsOverburdened } from '@/types/insights';

import styles from './styles.module.css';

export default function OverburdenedDrawer() {
  const { drawerIsOpen } = useContext(UIContext);

  const { data: overburdened, isLoading } = useInsightsPartQuery({
    insightsType: INSIGHTS_TYPE.OVERBURDENED,
    min: 0,
    max: 3,
    enabled: drawerIsOpen,
  });

  const { workspaceMode } = useContext(UserContext);

  const overburdenedDifference =
    (overburdened?.count && overburdened?.count - overburdened.data.length) ??
    0;
  function renderOverburdenedOrAvailableItem(
    type: 'overburdened' | 'available',
  ) {
    const shadeType = type === 'overburdened' ? 'shade-red-5' : 'shade-green-5';
    return function render(
      item: TInsightsOverburdened | TInsightsAvailable,
      key: number,
    ) {
      const differenceValueInDays = item?.difference;
      const isOneDay = differenceValueInDays === 1;
      return (
        <li className={styles.item} key={`${type}-${key}`}>
          <div className={styles.subItem}>
            <Avatar
              size="medium"
              image={item?.image?.ref}
              name={item?.firstName + ' ' + item?.lastName}
            />
            <Tooltip.Provider delayDuration={0}>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <p>{item?.firstName}</p>
                </Tooltip.Trigger>
                <Tooltip.Portal
                  container={document.getElementById('radix-tooltip-portal')}
                >
                  <Tooltip.Content className="TooltipContent" sideOffset={5}>
                    {item?.firstName + ' ' + item?.lastName}
                    <Tooltip.Arrow className="TooltipArrow" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
          <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <div className={classNames([styles.badge, styles[shadeType]])}>
                  {differenceValueInDays}
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal
                container={document.getElementById('radix-tooltip-portal')}
              >
                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                  {isOneDay
                    ? `${differenceValueInDays} ${t(
                        `common:${
                          workspaceMode === WORKSPACE_MODE.DAYS
                            ? 'day'
                            : 'hours'
                        }`,
                      )}  ${
                        type === 'overburdened'
                          ? t('common:insights:aboveWeeklyCapacity')
                          : t('common:insights:availableThisWeek')
                      }`
                    : `${differenceValueInDays} ${t(
                        `common:${workspaceMode?.toLowerCase()}`,
                      )} ${
                        type === 'overburdened'
                          ? t('common:insights:aboveWeeklyCapacity')
                          : t('common:insights:availableThisWeek')
                      }`}
                  <Tooltip.Arrow className="TooltipArrow" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </li>
      );
    };
  }
  const title = useMemo(() => t('common:insights.overburdened'), []);
  const rightTitle = useMemo(
    () =>
      `${format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'dd')} - ${format(
        subDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 2),
        'd MMM',
      )}`,
    [],
  );
  return (
    <DrawerCard
      draweId="overburdened"
      emoji="😰"
      isLoading={isLoading}
      loader={<ListSkeleton />}
      emptyLabel={t('common:insights.noOverburdenedLabel')}
      rightTitle={rightTitle}
      title={title}
    >
      {overburdened?.count && (
        <ul className={styles.list}>
          {overburdened?.data?.map(
            renderOverburdenedOrAvailableItem('overburdened'),
          )}
          {overburdenedDifference ? (
            <ShowMore
              insightsType={INSIGHTS_TYPE.OVERBURDENED}
              renderData={(data) => {
                return (
                  <ul className={styles.list}>
                    {data.map(
                      renderOverburdenedOrAvailableItem('overburdened'),
                    )}
                  </ul>
                );
              }}
              triggerLabel={`+ ${overburdenedDifference} ${t('common:more')}`}
              open={false}
              type="modal"
              titleClassName={styles.modalHeader}
              title={
                <Fragment>
                  <h2>😰 {title}</h2>
                  <p>{rightTitle}</p>
                </Fragment>
              }
            />
          ) : null}
        </ul>
      )}
    </DrawerCard>
  );
}
