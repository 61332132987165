export enum USER_ROLE {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
}

export enum SORTING_OPTIONS {
  LAST_UPDATE = 'last_update',
  CREATE_DATE = 'create_date',
  MANUAL = 'manual',
}

export enum ALLOCATION_EVENT_OPERATION_TYPE {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum WORKSPACE_MEMBER_PERMISSION {
  READ_AND_WRITE = 'READ_AND_WRITE',
  READ_ONLY = 'READ_ONLY',
}

export enum INSIGHTS_TYPE {
  ACTIVE_PROJECTS = 'ACTIVE_PROJECTS',
  CAPACITY = 'CAPACITY',
  TOP_PROJECTS = 'TOP_PROJECTS',
  STARTING_SOON = 'STARTING_SOON',
  OVERBURDENED = 'OVERBURDENED',
  AVAILABLE = 'AVAILABLE',
  WHOS_FREE = 'WHOS_FREE',
  THIS_WEEK = 'THIS_WEEK',
  WORKLOAD = 'WORKLOAD',
}

export enum PROJECT_TYPE {
  PROJECT = 'PROJECT',
  TIME_OFF = 'TIME_OFF',
}

export enum WORKSPACE_MODE {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export enum PROJECT_COLOR {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  LIME = 'lime',
  ORANGE = 'orange',
  PURPLE = 'purple',
  TEAL = 'teal',
  INDIGO = 'indigo',
  PINK = 'pink',
  GREY = 'grey',
}

export enum STORAGE_BASE_PATH {
  RESOURCES = 'resources',
  ACCOUNTS = 'accounts',
}

export enum STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
}
export enum PROJECT_STATUS {
  UNCONFIRMED = 'UNCONFIRMED',
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum SubscriptionStatusEnum {
  active = 'active',
  canceled = 'canceled',
  past_due = 'past_due',
  paused = 'paused',
  trialing = 'trialing',
}

export enum SubscriptionScheduledActionEnum {
  cancel = 'cancel',
  pause = 'pause',
  resume = 'resume',
}
