import { useCallback, useContext } from 'react';

import Button from '@components/Button';
import { t } from 'i18next';
import { isMobile } from 'react-device-detect';

import { UserContext } from '@/contexts/UserContext';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';

export default function ModalMobileExperience() {
  const { mobileExperienceAccepted, setMobileExperienceAccepted } =
    useContext(UserContext);
  const onCloseFn = useCallback(() => {
    setMobileExperienceAccepted(true);
  }, [setMobileExperienceAccepted]);
  return !mobileExperienceAccepted && isMobile ? (
    <Modal
      modalKey={`modal-mobile-experience`}
      panelClassName={[styles.panel]}
      isOpen={!mobileExperienceAccepted && isMobile}
      footer={
        <div className={styles.footer}>
          <Button
            label={t('common:modals.mobileExperience.primaryCtaLabel')}
            onClick={onCloseFn}
            size="large"
          />
        </div>
      }
    >
      <div className={styles.container}>
        <p>{t('common:modals.mobileExperience.description')}</p>
      </div>
    </Modal>
  ) : undefined;
}
