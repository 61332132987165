import { PropsWithChildren } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';

import styles from './styles.module.css';

import { ItemWithTooltipProps } from '.';

export function ItemWithTooltip({
  children,
  shortcut,
  shortcutLabel,
}: PropsWithChildren<ItemWithTooltipProps>) {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className="TooltipContent" sideOffset={5}>
          <div className={styles.detailTooltip}>
            {shortcutLabel && <span>{shortcutLabel}</span>}
            {shortcut && (
              <span className={styles.detailShortcut}>{shortcut}</span>
            )}
          </div>
          <Tooltip.Arrow className="TooltipArrow" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
}
