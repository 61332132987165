import React, { PropsWithChildren, useMemo } from 'react';

import { LineItem } from '@paddle/paddle-js/types/price-preview/price-preview';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';

import { titleCase } from '@/services/helpers';

import styles from './styles.module.css';
import Chip from '../Chip';
import PlanCardSkeleton from '../skeleton/PlanCardSkeleton';

type PlanCardProps = {
  item?: LineItem;
  info?: string | JSX.Element;
  features?: string[];
  variant?: 'starter' | 'pro';
  isLoading?: boolean;
  isPastDue?: boolean;
  isCurrentPlan?: boolean;
};

const priceRegex = /^(.)([0-9]+\.[0-9]?.)/i;

export default function PlanCard({
  item,
  info,
  features,
  variant = 'starter',
  children,
  isCurrentPlan = false,
  isPastDue = false,
  isLoading = false,
}: PropsWithChildren<PlanCardProps>) {
  const descriptionElement = useMemo(() => {
    if (item) {
      const [_, currencySign, value] = priceRegex.exec(
        item.formattedUnitTotals.subtotal,
      ) as Array<string>;
      const total = Number(value);
      return (
        <div className={styles.priceContainer}>
          <div className={styles.priceDescription}>
            <div className={styles.priceContainer}>
              <div className={styles.price}>
                <span>{currencySign}</span>
                <span className={styles.priveTotal}>{total}</span>
              </div>
              <span className={styles.interval}>
                {total > 0
                  ? ` / ${item.price.billingCycle?.interval}`
                  : undefined}
              </span>
            </div>
            {item.price.billingCycle?.interval === 'year' && (
              <Chip size="medium" type="informative">
                -20%
              </Chip>
            )}
          </div>
        </div>
      );
    }
    return undefined;
  }, [item]);

  const productType: 'FREE' | 'PRO' = useMemo(
    () => item?.product?.customData?.type as 'FREE' | 'PRO',
    [item?.product?.customData?.type],
  );

  return (
    <div
      className={classNames(styles.planCard, {
        [styles.isLoading]: isLoading,
        [styles.isCurrentPlan]: isCurrentPlan,
      })}
    >
      {!isLoading ? (
        <>
          <div className={styles.titlePriceWrapper}>
            {item?.product && (
              <div className={styles.productName}>
                <h1 className={styles.productNameType}>
                  {titleCase(productType)}{' '}
                </h1>
                {isCurrentPlan && (
                  <Chip
                    size="medium"
                    type={productType === 'FREE' ? 'neutral' : 'caution'}
                  >
                    Current plan
                  </Chip>
                )}
                {productType === 'PRO' && isCurrentPlan && isPastDue && (
                  <Chip size="medium" type={'critical'}>
                    past due
                  </Chip>
                )}
              </div>
            )}
            <span>{descriptionElement}</span>
            {info ? (
              React.isValidElement(info) ? (
                info
              ) : (
                <p>{info}</p>
              )
            ) : undefined}
          </div>
          <div className={styles.featuresList}>
            <ol>
              {features?.map((feature, index) => (
                <li key={`${feature}_${index}`}>
                  <span
                    className={classNames(styles.listIcon, {
                      [styles.green]: variant === 'pro',
                    })}
                  >
                    <IconCheck size={10} strokeWidth={4} />
                  </span>
                  <span>{feature}</span>
                </li>
              ))}
            </ol>
          </div>
          {children}
        </>
      ) : (
        <PlanCardSkeleton />
      )}
    </div>
  );
}
