import { http } from '@/services/api';
import {
  normalizeAssignedResource,
  normalizeFeatureFlags,
  normalizeInsights,
  normalizeInsightsPart,
  normalizeTokenInfo,
  normalizeUsageInfo,
  normalizeUserAccount,
  normalizeWorkloadPreferences,
  normilizeAccessToken,
} from '@/services/normalizer';
import { INSIGHTS_TYPE } from '@/types/enums';
import { Dto, TFeatureFlags, TResponse } from '@/types/generic';
import { TInsights, TInsightsPartKey } from '@/types/insights';
import { TResourceWorkspaceAssigned } from '@/types/timeline';
import {
  AccessTokenInfo,
  TUpdateWorkspaceBody,
  TUserAccount,
  TWorkspaceUsageInfo,
} from '@/types/workspace';

/**
 * This asynchronous function creates a new workspace.
 *
 * @param {Object} options - The parameters for the function.
 * @param {string} options.name - The name of the new workspace.
 *
 * @returns {Promise<TUserAccount>} The normalized user account data if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function createWorkspace(options: { name: string }) {
  const {
    data: { data },
  }: { data: TResponse<TUserAccount> } = await http.post(
    `workspaces/new`,
    options,
  );

  return normalizeUserAccount(data);
}

/**
 * This asynchronous function updates the details of a specific workspace.
 *
 * @param {Object} options - The parameters for the function.
 * @param {string} options.workspaceId - The ID of the workspace to be updated.
 * @param {string} options.workspaceName - The new name of the workspace.
 * @param {string} options.mode - The new mode of the workspace.
 *
 * @throws Will throw an error if both workspaceName and mode are not provided.
 * @throws Will call the errorHandler function if the request fails.
 */
export async function updateWorkspace(options: TUpdateWorkspaceBody) {
  if (!options.workspaceName && !options.mode)
    throw new Error('Update Workspace body can not be empty');
  return http.put(`workspaces/${options.workspaceId}`, {
    name: options.workspaceName,
    mode: options.mode,
  });
}

/**
 * This asynchronous function retrieves the resources assigned to a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.workspaceId - The ID of the workspace where the assigned resources are to be retrieved.
 *
 * @returns {Promise<TResourceWorkspaceAssigned[]>} The list of resources assigned to the workspace if the request is successful, otherwise an empty array.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getResourceWorkspaceAssigned({
  workspaceId,
}: {
  workspaceId: string;
}): Promise<TResourceWorkspaceAssigned[]> {
  const {
    data: { data },
  } = await http.get(`/workspaces/${workspaceId}/assigned-resources`);
  return (
    data?.map((dt: TResourceWorkspaceAssigned) =>
      normalizeAssignedResource(dt),
    ) ?? []
  );
}

/**
 * This asynchronous function retrieves the insights of a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the insights are to be retrieved. If null, the function will not proceed.
 *
 * @returns {Promise<TInsights>} The insights of the workspace if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getInsights({
  workspaceId,
}: {
  workspaceId?: string | null;
}) {
  const {
    data: { data },
  }: {
    data: TResponse<Dto<TInsights>>;
  } = await http.get(`/workspaces/${workspaceId}/insights`);
  return normalizeInsights(data);
}

/**
 * This asynchronous function retrieves a part of the insights of a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null} params.workspaceId - The ID of the workspace where the insights are to be retrieved. If null, the function will not proceed.
 * @param {number | undefined} params.min - The minimum value of the insights part to be retrieved. If undefined, the function will retrieve from the minimum available value.
 * @param {number | undefined} params.max - The maximum value of the insights part to be retrieved. If undefined, the function will retrieve up to the maximum available value.
 * @param {INSIGHTS_TYPE} params.insightsType - The type of the insights part to be retrieved.
 *
 * @returns {Promise<TInsightsPart>} The part of the insights of the workspace if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getInsightsPart<T extends INSIGHTS_TYPE>({
  insightsType,
  min,
  max,
  workspaceId,
}: {
  workspaceId?: string | null;
  min?: number;
  max?: number;
  insightsType: T;
}): Promise<ReturnType<typeof normalizeInsightsPart<T>>> {
  const {
    data: { data: responseData },
  }: {
    data: TResponse<Dto<TInsights[TInsightsPartKey<T>]>>;
  } = await http.get(`/workspaces/${workspaceId}/insights/${insightsType}`, {
    params: {
      min,
      max,
    },
  });
  return normalizeInsightsPart<T>(insightsType, responseData);
}

/**
 * This asynchronous function retrieves the feature flags associated with a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | null | undefined} params.workspaceId - The ID of the workspace where the feature flags are to be retrieved. If null or undefined, the function will not proceed.
 *
 * @returns {Promise<TFeatureFlags[]>} The feature flags associated with the workspace if the request is successful, otherwise an empty array.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getFeatureFlags({
  workspaceId,
}: {
  workspaceId: string | null | undefined;
}) {
  const {
    data: { data },
  }: { data: TResponse<TFeatureFlags[]> } = await http.get(
    `/workspaces/${workspaceId}/feature-flags`,
  );

  return data?.map(normalizeFeatureFlags) ?? [];
}

/**
 * This asynchronous function allows a user to join a feature flag in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.workspaceId - The ID of the workspace where the feature flag is located.
 * @param {string} params.code - The code of the feature flag to join.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function joinFeatureFlags({
  workspaceId,
  code,
}: {
  workspaceId: string;
  code: string;
}) {
  return http.post(`/workspaces/${workspaceId}/feature-flags/join`, { code });
}

/**
 * This asynchronous function allows a user to leave a feature flag in a specific workspace.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.workspaceId - The ID of the workspace where the feature flag is located.
 * @param {string} params.code - The code of the feature flag to leave.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function leaveFeatureFlags({
  workspaceId,
  code,
}: {
  workspaceId: string;
  code: string;
}) {
  return http.delete(`/workspaces/${workspaceId}/feature-flags/leave/${code}`);
}

export async function getUsageInfo({
  workspaceId,
}: {
  workspaceId: string;
}): Promise<TWorkspaceUsageInfo> {
  const {
    data: { data },
  } = await http.get<{ data: TWorkspaceUsageInfo }>(
    `/workspaces/${workspaceId}/usage-info`,
  );
  return normalizeUsageInfo(data);
}

export async function getWorkloadsPreferences(workspaceId: string) {
  const {
    data: { data },
  } = await http.get(`/workspaces/${workspaceId}/workload-preferences`);
  return data?.length ? data.map(normalizeWorkloadPreferences) : [];
}

export async function getAccessTokenInfo(
  workspaceId: string,
): Promise<AccessTokenInfo[]> {
  const {
    data: { data },
  } = await http.get(`/workspaces/${workspaceId}/access-token`);
  return data?.length ? data.map(normalizeTokenInfo) : [];
}

export async function createAccessToken(workspaceId: string) {
  const {
    data: { data },
  } = await http.post(`/workspaces/${workspaceId}/access-token`);
  return normilizeAccessToken(data);
}

export async function deleteAccessToken(
  workspaceId: string,
  accessTokenId: string,
) {
  return http.delete(
    `/workspaces/${workspaceId}/access-token/${accessTokenId}`,
  );
}
