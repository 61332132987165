import { getAuth } from '@firebase/auth';
import axios, { AxiosError, AxiosHeaders } from 'axios';
import i18next from 'i18next';

import { TResponseError } from '@/types/generic';

export async function getUserToken(): Promise<string | undefined> {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  return token;
}

export const http = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(async (config) => {
  const token = await getUserToken();
  if (token) {
    if (!config.headers) config.headers = new AxiosHeaders();
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['x-language'] = i18next.language;
  }
  return config;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorHandler(error: AxiosError<TResponseError>) {
  if (error?.response) {
    // Request made and server responded
    console.log(error?.response?.data);
    console.log(error?.response?.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
  // throw new Error(error);
  return error?.response?.data;
}
