import { IconCircleArrowUp, IconX } from '@tabler/icons-react';

import Button from '@/components/Button';
import FreePlanCard from '@/components/PlanCard/FreePlanCard';
import ProPlanCard from '@/components/PlanCard/ProPlanCard';
import UsageProgress from '@/components/UsageProgress';

import styles from './styles.module.css';
import Modal from '../Modal';

type Props = {
  modalOpened: boolean;
  onClose: () => void;
  changePlanTo: 'pro' | 'free';
};

export default function ModalUpgradePlan({
  modalOpened,
  onClose,
  changePlanTo,
}: Props) {
  return (
    <Modal
      modalKey={'plan-modal-upgrade'}
      isOpen={modalOpened}
      footer={undefined}
      enableClose={false}
      onClose={onClose}
      panelClassName={[styles.modalPanel]}
    >
      <Button
        icon={IconX}
        variant="ghost"
        size="medium"
        onClick={onClose}
        className={styles.closeButton}
      />
      <div className={styles.modalHeader}>
        <IconCircleArrowUp size={32} />
      </div>
      <UsageProgress />
      <div className={styles.modalContent}>
        <p>Upgrade to unlock more active projects.</p>
        {changePlanTo === 'pro' ? (
          <ProPlanCard onCheckoutOpen={onClose} />
        ) : (
          <FreePlanCard />
        )}
      </div>
    </Modal>
  );
}
