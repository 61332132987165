import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import { useNavigate } from 'react-router';

import { ROUTES } from '@/types/routes';

import { UserContext } from './UserContext';

export enum ONBOARDING_STATUS {
  INITIAL = 'INITIAL',
  SET_MODE = 'SET_MODE',
  ADD_MEMBER = 'ADD_MEMBER',
  DONE = 'DONE',
}

type OnboardingContextProps = {
  status?: ONBOARDING_STATUS;
  setNextStatus: () => void;
  navigateNextStep: () => void;
};
const OnboardingContext = createContext<OnboardingContextProps>({
  setNextStatus: () => {},
  navigateNextStep: () => {},
});
export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      'useOnboardingContext must be used within an OnboardingContextProvider',
    );
  }
  return context;
};

export default function OnboardingProvider({ children }: PropsWithChildren) {
  const [status, setStatus] = useState<ONBOARDING_STATUS | undefined>(
    undefined,
  );

  const { workspace } = useContext(UserContext);

  const navigate = useNavigate();

  const navigateNextStep = useCallback(() => {
    if (status === ONBOARDING_STATUS.SET_MODE) {
      if (workspace) {
        navigate(
          ROUTES.WORKSPACE_SET_TIME_MODE.replace(':workspaceId', workspace.id),
          {
            replace: true,
            state: { logoutBtn: false },
          },
        );
      }
    } else if (status === ONBOARDING_STATUS.ADD_MEMBER) {
      if (workspace) {
        navigate(ROUTES.ADD_MEMBER.replace(':workspaceId', workspace.id), {
          replace: true,
        });
      }
    } else if (status === ONBOARDING_STATUS.DONE) {
      navigate(ROUTES.ROOT, {
        replace: true,
      });
    }
  }, [navigate, status, workspace]);

  const setNextStatus = useCallback(() => {
    if (!status) {
      setStatus(ONBOARDING_STATUS.INITIAL);
    }
    if (status === ONBOARDING_STATUS.INITIAL) {
      setStatus(ONBOARDING_STATUS.SET_MODE);
    } else if (status === ONBOARDING_STATUS.SET_MODE) {
      setStatus(ONBOARDING_STATUS.ADD_MEMBER);
    } else if (status === ONBOARDING_STATUS.ADD_MEMBER) {
      setStatus(ONBOARDING_STATUS.DONE);
    }
  }, [status]);

  return (
    <OnboardingContext.Provider
      value={{
        status,
        setNextStatus,
        navigateNextStep,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}
