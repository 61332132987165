import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { getResourceWorkspaceAssigned } from '@/services/api/workspace/workspace';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TResourceWorkspaceAssigned } from '@/types/timeline';

export const WORKSPACE_ASSIGN_QUERY_KEY = 'get-resource-workspace-assigned';
export default function useResourceWorkspaceAssignedQuery({
  workspaceId,
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TResourceWorkspaceAssigned[]> & { workspaceId?: string }) {
  const { addNotification } = useContext(NotificationsContext);

  return useQuery(
    [WORKSPACE_ASSIGN_QUERY_KEY, workspaceId],
    () => getResourceWorkspaceAssigned({ workspaceId: workspaceId ?? '' }),
    {
      enabled: enabled && !!workspaceId,
      onError: (error: TResponseError) => {
        onError?.(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess,
    },
  );
}
