import classNames from 'classnames';
import { Emoji } from 'emoji-picker-react';

import { PROJECT_COLOR } from '@/types/enums';

import styles from './styles.module.css';

type Props = {
  color?: PROJECT_COLOR;
  emoji?: string;

  size?: number;
  emojiSize?: number;
  radius?: number;
};
export default function ProjectEmoji({
  color,
  emoji,
  size = 24,
  emojiSize = 16,
  radius = 6,
}: Props) {
  return (
    <>
      <span
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: `${radius}px`,
        }}
        className={classNames(styles.shade, styles[`shade-${color}`])}
      >
        <Emoji unified={emoji ?? ''} size={emojiSize} />
      </span>
    </>
  );
}
