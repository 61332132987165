import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getAuth, onIdTokenChanged } from '@firebase/auth';
import { io, Socket } from 'socket.io-client';

import { WebsocketMessage } from '@/types/websocket';

import { UserContext } from './UserContext';

const socket = io(import.meta.env.VITE_WS_URL, {
  path: `/${import.meta.env.VITE_WS_PATH}`,
  autoConnect: false,
  reconnection: true,
  reconnectionDelay: 1500,
});

type WebSocketContextType = {
  socket: Socket;
};

const WebSocketContext = createContext<WebSocketContextType>({
  socket,
});

export default function WebSocketProvider({
  children,
}: {
  children?: ReactNode | undefined;
}) {
  const { workspaceId } = useContext(UserContext);

  const [connected, setConnected] = useState<boolean>(false);
  const workspaceRef = useRef<string | null>(null);

  const auth = getAuth();
  useEffect(() => {
    return onIdTokenChanged(auth, (user) => {
      if (user) {
        user?.getIdToken().then((token) => {
          socket.auth = { token };
          if (socket.disconnected) socket.connect();
        });
      } else socket.disconnect();
    });
  }, [auth]);

  useEffect(() => {
    async function joinWorkspace(ws: string) {
      const { workspace } = await socket.emitWithAck('join-workspace', {
        workspaceId: ws,
      });
      workspaceRef.current = workspace;
      console.log(`joining workspace ${workspaceRef.current}`);
    }

    async function leaveWorkspace() {
      await socket.emitWithAck('leave-workspace');
      console.log('leave workspace');
    }

    if (connected)
      if (
        workspaceId &&
        (workspaceRef.current === null || workspaceRef.current !== workspaceId)
      ) {
        if (workspaceRef.current) {
          leaveWorkspace().then(() => joinWorkspace(workspaceId));
        } else {
          if (workspaceId && !workspaceRef.current) joinWorkspace(workspaceId);
          else if (!workspaceId) leaveWorkspace();
        }
      }
  }, [workspaceId, connected]);

  useEffect(() => {
    function onConnect() {
      console.log('connected', socket.connected);
      setConnected(true);
    }

    function onDisconnect(reason: Socket.DisconnectReason) {
      console.log(`Disconnected from WebSocket ${reason}`);
      setConnected(false);
      workspaceRef.current = null;
      if (reason !== 'io client disconnect') socket?.connect();
    }
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('reconnect', onConnect);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.on('reconnect', onConnect);
    };
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocketEvent<T = unknown>(
  event: string,
  callback: (data: WebsocketMessage<T>) => void,
) {
  const ctx = useContext(WebSocketContext);
  if (!ctx) {
    throw new Error(
      'useWebSocketEvent must be used within a WebSocketProvider',
    );
  }
  const userContext = useContext(UserContext);
  if (!userContext)
    throw new Error('useWebSocketEvent must be used within a UserProvider');

  const { workspaceId } = userContext;

  const { socket } = ctx;

  useEffect(() => {
    if (!socket || !workspaceId) return;

    if (!event) {
      console.error('useWebSocketEvent: event is required');
      return;
    }

    console.log('useWebSocketEvent useEffect', event);

    socket.on(event, callback);
    return () => {
      console.log('useWebSocketEvent cleanup', event);
      socket.off(event, callback);
    };
  }, [callback, event, socket, workspaceId]);
}
