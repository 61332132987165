import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { CheckoutOpenOptions } from '@paddle/paddle-js';
import classNames from 'classnames';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UIContext } from '@/contexts/UIContext';
import { UserContext, useSubscription } from '@/contexts/UserContext';
import useGetPricePreview from '@/hooks/paddle/useGetPricePreview';
import useGetProductById from '@/hooks/paddle/useGetProductById';
import usePaddle from '@/hooks/paddle/usePaddle';
import useReactivateSubscription from '@/hooks/paddle/useReactivateSubscription';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { USER_ROLE } from '@/types/enums';

import Button from '@/components/Button';
import { ContentPages } from '@/components/Modals/ModalUserProfile';
import Switch from '@/components/Switch';

import styles from './styles.module.css';
import PlanCard from '../PlanCard';

type Props = {
  onCheckoutOpen?: () => void;
};

export default function ProPlanCard({ onCheckoutOpen }: Props) {
  const { workspace, countryCode, account } = useContext(UserContext);

  const { subscription, skipTrailing, isPastDue } = useSubscription();

  const { openModal, setSelectedPageId, isPreferenceOpened } =
    useProfileModal();

  const [billedYearly, setBilledYearly] = useState(false);

  const { mutate: reactivateSubscription } = useReactivateSubscription();

  const isCurrentPlan = useMemo(() => !!subscription?.id, [subscription]);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (!workspace?.id) return;
    trackEvent(ANALYTICS_EVENTS.CHECKOUT_OPENED, workspace.id);
  }, [workspace?.id, trackEvent]);

  useEffect(() => {
    if (isCurrentPlan && subscription?.id) {
      setBilledYearly(subscription.billingCycle === 'year');
    }
  }, [isCurrentPlan, subscription]);

  const { paddle } = usePaddle();
  const { theme } = useContext(UIContext);

  const { data: proProduct } = useGetProductById({
    enabled: true,
    productId: import.meta.env.VITE_PADDLE_PRO_PRODUCT_ID,
  });

  const prices = useMemo(() => {
    if (!proProduct?.prices) return [];
    return proProduct?.prices?.filter((p) =>
      skipTrailing ? !p.trial : p.trial > 0,
    );
  }, [proProduct?.prices, skipTrailing]);

  const { data: proPreviews } = useGetPricePreview({
    priceId: prices.map((p) => p.id),
    countryCode: countryCode as string,
    enabled: !!prices.length && !!countryCode,
  });

  const proItemPreview = useMemo(() => {
    if (proPreviews) {
      const interval = billedYearly ? 'year' : 'month';
      return proPreviews.find(
        (item) => item.price.billingCycle?.interval === interval,
      );
    }
  }, [billedYearly, proPreviews]);

  const proPrice = useMemo(() => {
    return prices?.find(
      (item) => item.interval === (billedYearly ? 'YEARLY' : 'MONTHLY'),
    );
  }, [billedYearly, prices]);

  const openCheckout = useCallback(() => {
    let customerOption: CheckoutOpenOptions['customer'] | undefined;
    if (workspace?.customer) {
      customerOption = {
        id: workspace.customer.customerId,
      };

      if (workspace.customer.addressId) {
        customerOption.address = {
          id: workspace.customer.addressId,
        };
      }

      if (workspace?.customer.businessId) {
        customerOption.business = {
          id: workspace?.customer.businessId,
        };
      }
    } else if (account?.email) {
      customerOption = {
        email: account.email,
      };
    }
    if (proPrice)
      paddle?.Checkout.open({
        items: [{ priceId: proPrice.id, quantity: 1 }],
        customData: {
          workspaceId: workspace?.id ?? '',
          workspaceName: workspace?.name ?? '',
        },
        settings: {
          theme: theme === 'dark' ? 'dark' : 'light',
        },
        customer: customerOption,
      });
    onCheckoutOpen?.();
  }, [
    account?.email,
    onCheckoutOpen,
    paddle?.Checkout,
    proPrice,
    theme,
    workspace?.customer,
    workspace?.id,
    workspace?.name,
  ]);

  const actionElement = useMemo(() => {
    if (workspace?.role !== USER_ROLE.OWNER) return;
    if (!isCurrentPlan) {
      return (
        <Button
          label="Upgrade"
          size="large"
          onClick={openCheckout}
          className={styles.buttonLabel}
        />
      );
    } else if (subscription?.scheduledAction === 'cancel') {
      return (
        <Button
          label="Reactivate"
          size="large"
          variant="outline"
          className={styles.buttonLabel}
          onClick={() => {
            if (subscription?.id) {
              reactivateSubscription(subscription.id);
            }
          }}
        />
      );
    } else if (isPastDue) {
      return (
        <Button
          label="Update payment"
          size="large"
          variant="outline"
          className={styles.buttonLabel}
          onClick={() => {
            if (subscription?.id) {
              if (isPreferenceOpened) {
                setSelectedPageId(ContentPages.BILLING_INFO);
              } else {
                openModal(ContentPages.BILLING_INFO);
                onCheckoutOpen?.();
              }
            }
          }}
        />
      );
    }
    return undefined;
  }, [
    isCurrentPlan,
    isPastDue,
    isPreferenceOpened,
    onCheckoutOpen,
    openCheckout,
    openModal,
    reactivateSubscription,
    setSelectedPageId,
    subscription?.id,
    subscription?.scheduledAction,
    workspace?.role,
  ]);

  return (
    <PlanCard
      isCurrentPlan={isCurrentPlan}
      isPastDue={isPastDue}
      isLoading={!(proProduct && proItemPreview)}
      item={proItemPreview}
      features={proProduct?.features ?? []}
      info={
        <div className={classNames(styles.infoBox, styles.billedYearly)}>
          <Switch
            id={'yearly'}
            checked={billedYearly}
            disabled={isCurrentPlan}
            onChange={(check) => setBilledYearly(check)}
            label={`Billed ${billedYearly ? 'yearly' : 'monthly'}`}
          />
        </div>
      }
      variant="pro"
    >
      {actionElement}
    </PlanCard>
  );
}
