import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { t } from 'i18next';
import { filter } from 'lodash';
import { useCopyToClipboard } from 'react-use';

import { UserContext } from '@/contexts/UserContext';
import useCreateAccessToken from '@/hooks/access-token/useCreateAccessToken';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { USER_ROLE } from '@/types/enums';
import { AccessTokenInfo, TUserWorkspace } from '@/types/workspace';

import Button from '@/components/Button';
import Section from '@/components/Section';
import SelectDropdown from '@/components/SelectDropdown';

import TokenInfoContainer from './TokenInfoContainer';
import styles from './styles.module.css';

function accessTokenReducer(
  state: Record<string, string>,
  action: { accessToken: string; id: string; action: 'add' | 'remove' },
) {
  switch (action.action) {
    case 'add':
      return { ...state, [action.id]: action.accessToken };
    case 'remove':
      // eslint-disable-next-line no-case-declarations
      const { [action.id]: _, ...rest } = state;
      return rest;
    default:
      return state;
  }
}

export default function ModalApi() {
  const { account, workspace: currentWorkspace } = useContext(UserContext);

  const workspaces = useMemo(
    () =>
      filter(
        account?.workspaces ?? [],
        (w) => w.role === USER_ROLE.OWNER && !w.accessToken?.id,
      ),
    [account?.workspaces],
  );

  const [workspace, setWorkspace] = useState<TUserWorkspace | undefined>(
    undefined,
  );

  useEffect(() => {
    setWorkspace(workspaces?.[0]);
  }, [workspaces]);

  const [accessTokens, setAccessTokens] = useReducer(accessTokenReducer, {});
  const { trackEvent } = useAnalytics();

  const { mutate: createToken, isLoading: isCreating } = useCreateAccessToken({
    onSuccess: (data) => {
      if (data) {
        copyAccessTokenFn(data.accessToken);
        setAccessTokens({
          action: 'add',
          ...data,
        });

        trackEvent(
          ANALYTICS_EVENTS.PERSONAL_ACCESS_TOKEN_CREATED,
          currentWorkspace?.id as string,
        );
      }
    },
  });

  const tokens: Array<AccessTokenInfo & { workspaceId: string }> =
    useMemo(() => {
      const tks = (account?.workspaces
        .filter((w) => w.accessToken?.id)
        .map((w) => ({ ...w.accessToken, workspaceId: w.id })) ?? []) as Array<
        AccessTokenInfo & { workspaceId: string }
      >;
      tks.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      return tks;
    }, [account?.workspaces]);

  const [_copyState, copyAccessToken] = useCopyToClipboard();
  const createAccessTokenFn = useCallback(() => {
    if (!workspace?.id) return;
    createToken(workspace.id);
    setWorkspace(undefined);
  }, [createToken, workspace?.id]);

  const copyAccessTokenFn = useCallback(
    (accessToken: string) => {
      if (accessToken) copyAccessToken(accessToken);
    },
    [copyAccessToken],
  );

  return (
    <Section title={t('common:modals.api.title')}>
      <div className={styles.container}>
        <p className={styles.description}>
          {t('common:modals.api.description')}
        </p>
        <p style={{ fontWeight: 800 }} className={styles.description}>
          {t('common:modals.api.restriction')}
        </p>
        {workspaces?.length > 0 && (
          <div className={styles.actionContainer}>
            <SelectDropdown
              disabled={workspaces?.length <= 1}
              label={workspace?.name ?? ''}
              placeholder={
                !workspaces?.length
                  ? 'No workspaces found'
                  : 'Select workspace...'
              }
            >
              {workspaces.map((w) => (
                <SelectDropdown.Item
                  checked={w.id === workspace?.id}
                  key={w.id}
                  label={w.name}
                  onSelect={() => setWorkspace(w)}
                />
              ))}
            </SelectDropdown>
            <Button
              label={t('common:modals.api.actionCreate')}
              size="medium"
              disabled={!workspace?.id || isCreating}
              isLoading={isCreating}
              onClick={createAccessTokenFn}
            />
          </div>
        )}
        {tokens?.length
          ? tokens.map((tk) => (
              <TokenInfoContainer
                key={tk.id}
                token={tk}
                accessToken={accessTokens?.[tk.id]}
              />
            ))
          : undefined}
        <div className={styles.linkContainer}>
          <span className={styles.linkText}>
            {t('common:modals.api.learnMore')}{' '}
            <a
              href={import.meta.env.VITE_API_DOC_URL}
              target="_blank"
              rel="noreferrer"
            >
              <u>{t('common:modals.api.accessToken')}</u>
            </a>
          </span>
        </div>
      </div>
    </Section>
  );
}
