import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Button from '@components/Button';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { t } from 'i18next';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';

import { UserContext } from '@/contexts/UserContext';
import useDeleteMyAccount from '@/hooks/account/useDeleteMyAccount';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import Input from '../../Input';

const MODAL_SCOPE = 'ModaldeleteMyAccount-scope';
type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export default function ModalDeleteMyAccount({ isOpen, onClose }: Props) {
  const { account } = useContext(UserContext);

  const email = useMemo(() => account?.email, [account]);

  const { mutate: deleteMyAccountAPI, isLoading } = useDeleteMyAccount();
  const [value, setValue] = useState('');
  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const { disableScope, enableScope } = useHotkeysContext();
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) enableScope(MODAL_SCOPE);
    else disableScope(MODAL_SCOPE);
    return () => disableScope(MODAL_SCOPE);
  }, [disableScope, enableScope, isOpen]);

  useHotkeys(
    'enter',
    () => value && value === email && !isLoading && deleteMyAccount(),
    { scopes: MODAL_SCOPE, enableOnFormTags: true, preventDefault: true },
  );

  const deleteMyAccount = useCallback(() => {
    deleteMyAccountAPI();
  }, [deleteMyAccountAPI]);

  return (
    <Modal
      isOpen={isOpen}
      modalKey={`modal-delete-my-account`}
      onClose={onClose}
      title={t('common:modals.deleteMyAccount.title')}
      footer={
        <>
          <Button
            label={t('common:modals.deleteMyAccount.primaryCtaLabel')}
            onClick={deleteMyAccount}
            isLoading={isLoading}
            disabled={value !== email || isLoading}
            variant="destructive"
            size="medium"
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <div className={styles.container}>
        <div className={styles.alert}>
          <IconAlertCircleFilled size={24} />
          <p>{t('common:modals.deleteMyAccount.description')}</p>
        </div>
        <p className={styles.actionLabel}>
          {t('common:modals.deleteMyAccount.actionLabel', {
            email: account?.email,
          })}
        </p>
        <Input ref={ref} value={value} onChange={onChangeFn} name="destroy" />
      </div>
    </Modal>
  );
}
