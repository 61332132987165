import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { SEARCH_PROJECTS_KEY } from '@/hooks/workspace/projects/useProjectsQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { updateProject } from '@/services/api/workspace/projects';
import { PROJECT_COLOR, PROJECT_STATUS } from '@/types/enums';

import { ContextData } from './mutationsFunctions/onUpdateProjectMutate';
import { COMPLETED_PROJECT_QUERY } from './useCompletedProject';
import { PROJECT_COUNT_BY_STATUS_QUERY_KEY } from './useProjectCountByStatus';
import { TIMELINE_PROJECT_QUERY_KEY } from './useTimelineProjectsQuery';

type Props = {
  name: string;
  projectId: string;
  emoji?: string;
  color: PROJECT_COLOR;
  status: PROJECT_STATUS;
};

export default function useUpdateProject() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, Error, Props>(
    async (prj: Props): Promise<void> => {
      await updateProject({ workspaceId, ...prj });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RESOURCES_QUERY_KEY]);
        queryClient.invalidateQueries([SEARCH_PROJECTS_KEY]);
        queryClient.invalidateQueries([INSIGHT_PART_QUERY_KEY]);
        queryClient.invalidateQueries([PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY]);
        queryClient.invalidateQueries([TIMELINE_PROJECT_QUERY_KEY]);
        queryClient.invalidateQueries([TIMELINE_RESOURCE_QUERY_KEY]);
        queryClient.invalidateQueries([COMPLETED_PROJECT_QUERY]);
        queryClient.invalidateQueries([PROJECT_COUNT_BY_STATUS_QUERY_KEY]);
      },
      onError: (error: Error, variables, context) => {
        const ctx = context as ContextData;
        if (!ctx?.oldStatus || !ctx?.newStatus) return;
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
