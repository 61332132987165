import { useQuery } from '@tanstack/react-query';

import { http } from '@/services/api';
import { normalizeSubscription } from '@/services/normalizer';
import { BaseResponse } from '@/types/base-responses';
import { TSubscriptionDto } from '@/types/workspace';

type Props = {
  workspaceId: string;
  enabled?: boolean;
};
export const GET_SUBSCRIPTION_KEY = 'get-subscription';
export default function useGetSubscription({
  workspaceId,
  enabled = true,
}: Props) {
  return useQuery(
    [GET_SUBSCRIPTION_KEY, workspaceId],
    async ({ queryKey: [_, ws] }) => {
      const { data } = await http.get<BaseResponse<TSubscriptionDto>>(
        `/workspaces/${ws}/subscriptions`,
      );

      return data.data ? normalizeSubscription(data.data) : null;
    },
    {
      enabled,
    },
  );
}
