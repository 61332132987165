import { IconAlertCircleFilled } from '@tabler/icons-react';

import styles from './styles.module.css';
import Modal from '../Modal';

type Props = Parameters<typeof Modal>[0] & {
  warningDescription?: JSX.Element | string;
};

export default function ModalWarning({ warningDescription, ...props }: Props) {
  return (
    <Modal {...props}>
      <div className={styles.container}>
        {warningDescription && (
          <div className={styles.alert}>
            <IconAlertCircleFilled size={24} />
            <p>{warningDescription}</p>
          </div>
        )}
        {props.children}
      </div>
    </Modal>
  );
}
