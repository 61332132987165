import { useCallback, useContext, useRef, useState } from 'react';

import Avatar from '@components/Avatar';
import Button from '@components/Button';
import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  IconChevronDown,
  IconChevronRight,
  IconDots,
  IconGripVertical,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import { find } from 'lodash';
import { RightSlot, contextMenu } from 'react-contexify-moze';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import { getCssVariable } from '@/services/helpers';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TUserWorkspace, TWorkspaceAccess } from '@/types/workspace';

import ShortcutMenu from '@/components/ShortcutMenu';

import styles from './styles.module.css';
import ModalDeleteTeamMember from '../../../Modals/ModalDeleteTeamMember';
import ModalEditTeamMember from '../../../Modals/ModalEditTeamMember';
import RightSlotDelete from '../../common/utils/RightSlotDelete';

type Props = {
  id: string;
  isCompressed?: boolean;
  onCompress?: () => void;
  firstName: string;
  lastName: string;
  image?: TAttachmentDataUrls;
  capacity: number;
  isDraggableOverlay?: boolean;
  workspaceAccess?: TWorkspaceAccess;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function ResourceHeader({
  id,
  isCompressed,
  onCompress,
  isDraggableOverlay,
  capacity,
  firstName,
  lastName,
  image,
  workspaceAccess,
  sortHandlerProps,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>();

  const [isModalDeleteTeamMemberOpen, setIsModalDeleteTeamMemberOpen] =
    useState(false);
  const [isModalEditTeamMemberOpen, setIsModalEditTeamMemberOpen] =
    useState(false);

  const onOpenModalDeleteTeamMember = useCallback(
    () => setIsModalDeleteTeamMemberOpen(true),
    [],
  );
  const onCloseModalDeleteTeamMember = useCallback(
    () => setIsModalDeleteTeamMemberOpen(false),
    [],
  );

  const onOpenModalEditTeamMember = useCallback(
    () => setIsModalEditTeamMemberOpen(true),
    [],
  );
  const onCloseModalEditTeamMember = useCallback(
    () => setIsModalEditTeamMemberOpen(false),
    [],
  );

  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (160 - width), y: bottom + 8 };
    return menuPositionRef.current;
  }

  const MENU_ID = `team-context-menu-${firstName}-${lastName}`;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [menuIsOpen, MENU_ID],
  );

  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const { layoutIsExpanded } = useContext(UIContext);

  return (
    <>
      <div
        aria-hidden
        className={classNames(styles.container, {
          [styles.isExpanded]: layoutIsExpanded,
          [styles.menuIsOpen]: menuIsOpen,
        })}
        onClick={onCompress}
      >
        <div className={styles.content}>
          {sortHandlerProps?.activator && (
            <div
              className={classNames(styles.sortHandler, {
                [styles.sortHandlerHidden]:
                  workspace?.permission ===
                  WORKSPACE_MEMBER_PERMISSION.READ_ONLY,
              })}
              {...sortHandlerProps?.listeners}
              {...sortHandlerProps?.attributes}
              ref={sortHandlerProps?.activator}
            >
              <IconGripVertical
                size={16}
                color={getCssVariable('--color-neutral-500')}
              />
            </div>
          )}
          {isDraggableOverlay && (
            <div className={styles.sortHandler}>
              <IconGripVertical size={16} />
            </div>
          )}
          <span className={styles.collapse}>
            {!isCompressed ? (
              <IconChevronDown size={16} />
            ) : (
              <IconChevronRight size={16} />
            )}
          </span>
          <div className={styles.avatar}>
            <Avatar
              image={image?.ref}
              name={`${firstName} ${lastName}`}
              size="medium"
            />
          </div>
          <div className={styles.desktop}>
            <p className={styles.resourceName}>
              {firstName} {lastName}
            </p>
            {workspace?.permission ===
              WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
              <Button
                ref={triggerRef}
                icon={IconDots}
                variant="ghost"
                size="small"
                onClick={onClickFn}
              />
            )}
          </div>
        </div>
      </div>
      <ShortcutMenu
        onVisibilityChange={(isVisible: boolean) => setMenuIsOpen(isVisible)}
        animation={false}
        id={MENU_ID}
      >
        <ShortcutMenu.Item keyMatch="e" keyHandler={onOpenModalEditTeamMember}>
          <IconPencil size={20} style={{ marginRight: 8 }} />
          {t('common:editContextMenuActions.edit')} <RightSlot>E</RightSlot>
        </ShortcutMenu.Item>
        <ShortcutMenu.Item
          disabled={workspaceAccess?.email === account?.email}
          keyMatch={'shift+backspace'}
          keyHandler={onOpenModalDeleteTeamMember}
        >
          <IconTrash size={20} style={{ marginRight: 8 }} />
          {t('common:editContextMenuActions.delete')} <RightSlotDelete />
        </ShortcutMenu.Item>
      </ShortcutMenu>
      {isModalDeleteTeamMemberOpen && (
        <ModalDeleteTeamMember
          isOpen={isModalDeleteTeamMemberOpen}
          resourceId={id}
          key={`delete-resource-${id}`}
          name={`${firstName} ${lastName}`}
          onClose={onCloseModalDeleteTeamMember}
        />
      )}
      {isModalEditTeamMemberOpen && (
        <ModalEditTeamMember
          isOpen={isModalEditTeamMemberOpen}
          onClose={onCloseModalEditTeamMember}
          resourceId={id}
          image={image}
          onDelete={() => {
            onCloseModalEditTeamMember();
            onOpenModalDeleteTeamMember();
          }}
          member={{
            firstName,
            lastName,
            capacity,
            workspaceAccess,
          }}
        />
      )}
    </>
  );
}
