import { useMemo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';

type BaseProps = {
  children: string;
  size?: 'small' | 'medium';
  type?:
    | 'alert'
    | 'informative'
    | 'success'
    | 'critical'
    | 'neutral'
    | 'caution';
  asButton?: boolean;
};
type PropsDiv = BaseProps & {
  asButton?: false;
  onClick?: never;
};

type PropsButton = BaseProps & {
  asButton: true;
  onClick: () => void;
};

type Props = PropsDiv | PropsButton;

export default function Chip(props: Props) {
  const { children, size = 'medium', type = 'neutral' } = props;
  const ContainerElement = useMemo(
    () => (props.asButton ? 'button' : 'div'),
    [props.asButton],
  );
  return (
    <ContainerElement
      className={classNames([styles.container, styles[type], styles[size]], {
        // [styles.button]: props.asButton,
      })}
      {...(props.asButton ? { onClick: props.onClick } : {})}
    >
      {children}
    </ContainerElement>
  );
}
