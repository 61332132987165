import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { UIContext } from '@contexts/UIContext';
import type { InfiniteData } from '@tanstack/react-query';
import type { Virtualizer } from '@tanstack/react-virtual';
import { useVirtualizer } from '@tanstack/react-virtual';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import {
  add,
  eachWeekOfInterval,
  setDefaultOptions,
  startOfToday,
  startOfWeek,
  sub,
} from 'date-fns';
import { flatMap, noop } from 'lodash';

import useProjectListWithResourcesStatus from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { getCssVariable } from '@/services/helpers';
import { TPagedQuery } from '@/types/generic';
import {
  TProjectListWithResources,
  TProjectListWithResourcesStatus,
  TResourceItemList,
} from '@/types/timeline';
export const WEEK_ROW_HIGH_SIZE_COMPRESSED = 56;
export const WEEK_ROW_HIGH_SIZE_EXPANDED = 80;
export const WEEKS_TO_ADD = 20;
export const WORKING_DAYS_IN_A_WEEK = 5;

type TTimeInterval = { when?: 'before' | 'after'; start: Date; end: Date };

export type TData = InfiniteData<
  TPagedQuery<TProjectListWithResourcesStatus<TResourceItemList>>
>;

export enum SORTING_OPTIONS {
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  MANUAL = 'manual',
}

const TimelineProjectsContext = React.createContext<{
  currentScrollSize: number;
  isFetching: boolean;
  sortOption?: SORTING_OPTIONS;
  setSortOption: React.Dispatch<
    React.SetStateAction<SORTING_OPTIONS | undefined>
  >;
  leftPadding: number;
  onNextFn: () => void;
  onPrevFn: () => void;
  fetchNextPage?: ReturnType<
    typeof useProjectListWithResourcesStatus
  >['fetchNextPage'];
  isSorting: boolean;
  setIsSorting: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentScrollSize: React.Dispatch<React.SetStateAction<number>>;
  setResetViewOnToday: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveBlockIds: React.Dispatch<React.SetStateAction<string[]>>;
  setExpandedByIds: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveProjectId: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveResourceId: React.Dispatch<React.SetStateAction<string | null>>;
  resetViewOnToday: boolean;
  expandedByIds: string[] | null;
  isFetchingNextPage: boolean;
  totalProjectsCount: number;
  hasNextPage: boolean;
  isLoading: boolean;
  selectedBlockId: string | null;
  setSelectedBlockId: React.Dispatch<React.SetStateAction<string | null>>;
  // isLoadingtimelineBlocksQuery: boolean;
  activeBlockIds: string[];
  weekSizeInPx: number;
  activeProjectId: string | null;
  activeResourceId: string | null;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  ref: React.RefObject<HTMLDivElement>;
  sidebarRef: React.RefObject<HTMLDivElement>;

  updateTimeInterval: (when: 'before' | 'after') => void;
  timeInterval: TTimeInterval;
  weeks: Date[];
  onExpandOrCompressAllFn: () => void;
  data?: TData | undefined;
}>({
  currentScrollSize: 0,
  sortOption: undefined,
  setSortOption: noop,
  isSorting: false,
  setIsSorting: noop,
  isLoading: false,
  onNextFn: noop,
  isFetching: false,
  totalProjectsCount: 0,
  onPrevFn: noop,
  hasNextPage: false,
  isFetchingNextPage: false,
  leftPadding: 0,
  setExpandedByIds: noop,
  onExpandOrCompressAllFn: noop,
  activeBlockIds: [],
  activeProjectId: null,
  activeResourceId: null,
  selectedBlockId: null,
  setSelectedBlockId: noop,
  expandedByIds: [],
  weekSizeInPx: WEEK_ROW_HIGH_SIZE_COMPRESSED,
  setCurrentScrollSize: noop,
  virtualizer: {} as Virtualizer<HTMLDivElement, Element>,
  resetViewOnToday: true,
  ref: {} as React.RefObject<HTMLDivElement>,
  sidebarRef: {} as React.RefObject<HTMLDivElement>,
  setResetViewOnToday: noop,
  setActiveBlockIds: noop,
  setActiveProjectId: noop,
  setActiveResourceId: noop,
  updateTimeInterval: noop,

  timeInterval: { start: new Date(), end: new Date() },
  weeks: [],
  data: undefined,
});

const TimelineProjectProvider = ({ children }: PropsWithChildren) => {
  setDefaultOptions({ weekStartsOn: 1 });
  const [isSorting, setIsSorting] = useState(false);
  const { layoutIsExpanded } = useContext(UIContext);

  const [expandedByIds, setExpandedByIds] = useLocalStorage<string[]>(
    `compressed-projects`,
    [],
  );

  const isMdDevice = useMediaQuery('(min-width: 992px)');

  const [currentScrollSize, setCurrentScrollSize] = useState(0);
  const [resetViewOnToday, setResetViewOnToday] = useState(true);
  const [activeBlockIds, setActiveBlockIds] = useState<string[]>([]);
  const [activeProjectId, setActiveProjectId] = useState<string | null>(null);
  const [activeResourceId, setActiveResourceId] = useState<string | null>(null);
  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null);
  const [sortOption, setSortOption] = useState<SORTING_OPTIONS | undefined>(
    undefined,
  );
  const leftPadding = isMdDevice
    ? Number(getCssVariable('--sidebar-width')?.replace('px', ''))
    : 0;

  const today = startOfToday();
  const currentWeek = startOfWeek(today);
  const initialTimeInterval = {
    start: sub(currentWeek, { weeks: 40 }),
    end: add(currentWeek, { weeks: 40 }),
  };

  const [timeInterval, setTimeInterval] =
    useState<TTimeInterval>(initialTimeInterval);

  const { data, fetchNextPage, ...other } = useProjectListWithResourcesStatus();
  const weekSizeInPx = layoutIsExpanded
    ? WEEK_ROW_HIGH_SIZE_EXPANDED
    : WEEK_ROW_HIGH_SIZE_COMPRESSED;

  const updateTimeInterval = (when: 'before' | 'after') => {
    let newTimeInterval;
    if (when === 'before') {
      newTimeInterval = {
        start: sub(timeInterval.start, { weeks: WEEKS_TO_ADD }),
        end: timeInterval.end,
      };
    } else {
      newTimeInterval = {
        start: timeInterval.start,
        end: add(timeInterval.end, { weeks: WEEKS_TO_ADD }),
      };
    }
    setTimeInterval({ when, ...newTimeInterval });
  };

  const weeks = useMemo(() => eachWeekOfInterval(timeInterval), [timeInterval]);
  const ref = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const onExpandOrCompressAllFn = useCallback(() => {
    if (expandedByIds?.length) {
      setExpandedByIds([]);
    } else {
      setExpandedByIds(
        flatMap(
          flatMap(data?.pages, (p) => p.results),
          (d) => flatMap(d?.projects),
        ).map(
          (item: TProjectListWithResources<TResourceItemList>) => item.id,
        ) ?? [],
      );
    }
  }, [data, expandedByIds?.length, setExpandedByIds]);
  const virtualizer = useVirtualizer({
    count: weeks.length,
    horizontal: true,
    overscan: 10,
    getScrollElement: () => ref.current,
    estimateSize: () => weekSizeInPx,
  });

  const onNextFn = useCallback(() => {
    virtualizer.scrollBy(weekSizeInPx * 8);
  }, [virtualizer, weekSizeInPx]);

  const onPrevFn = useCallback(() => {
    virtualizer.scrollBy(-weekSizeInPx * 8);
  }, [virtualizer, weekSizeInPx]);

  useEffect(() => {
    virtualizer.measure();
    setCurrentScrollSize((prevValue) => {
      if (prevValue !== 0) {
        const newValue = layoutIsExpanded
          ? (WEEK_ROW_HIGH_SIZE_EXPANDED * prevValue) /
            WEEK_ROW_HIGH_SIZE_COMPRESSED
          : (WEEK_ROW_HIGH_SIZE_COMPRESSED * prevValue) /
            WEEK_ROW_HIGH_SIZE_EXPANDED;
        virtualizer.scrollToOffset(newValue);
        return newValue;
      } else {
        return prevValue;
      }
    });
  }, [layoutIsExpanded, virtualizer]);

  useEffect(() => {
    setCurrentScrollSize((prevValue) =>
      virtualizer.scrollOffset !== prevValue
        ? virtualizer.scrollOffset
        : prevValue,
    );
  }, [setCurrentScrollSize, virtualizer.scrollOffset]);

  return (
    <TimelineProjectsContext.Provider
      value={{
        data: data as TData,
        setIsSorting,
        isSorting,
        setActiveBlockIds,
        currentScrollSize,
        setCurrentScrollSize,
        virtualizer,
        setActiveResourceId,
        activeResourceId,
        activeBlockIds,
        selectedBlockId,
        setSelectedBlockId,
        weekSizeInPx,
        expandedByIds: expandedByIds,
        setExpandedByIds: setExpandedByIds,
        ref,
        sidebarRef,
        resetViewOnToday,
        leftPadding,
        onExpandOrCompressAllFn,
        setResetViewOnToday,
        hasNextPage: other?.hasNextPage ?? false,
        isFetchingNextPage: other?.isFetchingNextPage ?? false,
        isLoading: other?.isLoading ?? false,
        updateTimeInterval,
        setSortOption,
        sortOption,
        timeInterval,
        totalProjectsCount: data?.pages[0]?.totalCount ?? 0,
        weeks,
        activeProjectId,
        setActiveProjectId,
        fetchNextPage,
        onNextFn,
        onPrevFn,
        isFetching: other?.isFetching ?? false,
      }}
    >
      {children}
    </TimelineProjectsContext.Provider>
  );
};

export { TimelineProjectProvider, TimelineProjectsContext };
