import * as Tooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';

import styles from './styles.module.css';

export type GroupItemProps = {
  itemId: string;
  icon: JSX.Element;
  title: string;

  isActive?: boolean;
  onClick?: (groupId: string) => void;
};

export type GroupProps = {
  title?: string | null;
  items?: GroupItemProps[];
  tooltip?: JSX.Element | string;
};

export function SectionGroup(props: GroupProps) {
  return (
    <div className={styles.container}>
      {props?.title &&
        (props.tooltip ? (
          <Tooltip.Provider>
            <Tooltip.Root delayDuration={0.5}>
              <Tooltip.Trigger asChild>
                <h4 className={styles.title}> {props.title}</h4>
              </Tooltip.Trigger>

              <Tooltip.Content
                className="TooltipContent"
                sideOffset={5}
                align="end"
                side="bottom"
              >
                {props.tooltip}
                <Tooltip.Arrow className="TooltipArrow" />
              </Tooltip.Content>
            </Tooltip.Root>
          </Tooltip.Provider>
        ) : (
          <h4 className={styles.title}> {props.title}</h4>
        ))}

      {props.items?.map((item) => {
        return (
          <button
            onClick={() => item.onClick?.(item.itemId)}
            className={classNames(styles.item, {
              [styles.active]: item.isActive,
            })}
            key={item.title}
          >
            <div className={styles['item-icon']}>{item.icon}</div>
            <div className={styles['item-title']}>{item.title}</div>
          </button>
        );
      })}
    </div>
  );
}
