import { useContext } from 'react';

import type { QueryKey } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep, find } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { updateProjectStatus } from '@/services/api/workspace/projects';
import { PROJECT_STATUS } from '@/types/enums';
import { TResources } from '@/types/timeline';

import { RESOURCES_QUERY_KEY } from './useResourcesQuery';
import { ContextData } from '../projects/mutationsFunctions/onUpdateProjectMutate';
import { COMPLETED_PROJECT_QUERY } from '../projects/useCompletedProject';
import { PROJECT_COUNT_BY_STATUS_QUERY_KEY } from '../projects/useProjectCountByStatus';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '../projects/useProjectListWithResourcesStatusQuery';

type Props = {
  projectId: string;
  status: PROJECT_STATUS;
  resourceId?: string;
};

export function useUpdateProjectStatus() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, Error, Props>(
    async (prj: Props): Promise<void> => {
      updateProjectStatus({ workspaceId, ...prj });
    },
    {
      onSuccess: (_data, variables, _context) => {
        queryClient.invalidateQueries([
          PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY,
          workspaceId,
        ]);

        if (variables.status === PROJECT_STATUS.COMPLETED) {
          queryClient.invalidateQueries([COMPLETED_PROJECT_QUERY]);
          queryClient.invalidateQueries([
            PROJECT_COUNT_BY_STATUS_QUERY_KEY,
            workspaceId,
            PROJECT_STATUS.COMPLETED,
          ]);
        }
      },
      onMutate: (prj: Props) => {
        const key = [RESOURCES_QUERY_KEY, workspaceId] as QueryKey;
        const queryData = queryClient.getQueryData(key) as TResources[];
        const clonedQueryData = cloneDeep(queryData);

        const currentPrj = find(clonedQueryData, {
          id: prj.resourceId,
        })?.projects.find((p) => p.id === prj.projectId);

        if (!currentPrj) return;
        currentPrj.status = prj.status;
        queryClient.setQueryData(key, clonedQueryData);
        return { queryData };
      },
      onError: (error: Error, _variables, context) => {
        const ctx = context as ContextData;
        if (!ctx?.oldStatus || !ctx?.newStatus) return;
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
