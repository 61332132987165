import { useCallback, useContext, useState } from 'react';

import { IconPencil } from '@tabler/icons-react';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { USER_ROLE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TResourceWorkspaceAssigned } from '@/types/timeline';
import { TUserMember } from '@/types/workspace';

import styles from './styles.module.css';
import Avatar from '../Avatar';
import Button from '../Button';
import Chip from '../Chip';
import ModalDeleteTeamMember from '../Modals/ModalDeleteTeamMember';
import ModalEditTeamMember from '../Modals/ModalEditTeamMember';

type WorkspaceResourceListItemProps = TResourceWorkspaceAssigned & {
  resourceId?: string;
  onDelete?: typeof noop;
  onUpdate?: typeof noop;
  workspaceName?: string;
};

export function WorkspaceResourceListItem({
  resourceId,
  firstName,
  lastName,
  email,
  image,
  capacity,
  role,
  workspaceAccess,
  permission,
  onDelete = noop,
  onUpdate = noop,
  workspaceName,
}: WorkspaceResourceListItemProps) {
  const { addNotification } = useContext(NotificationsContext);
  const [isModalEditResourceOpen, setIsModalEditResourceOpen] = useState(false);
  const [isModalDeleteResourceOpen, setIsModalDeleteResourceOpen] =
    useState(false);
  const onCloseDeleteModal = useCallback(
    (deleted?: boolean) => {
      onDelete();
      setIsModalDeleteResourceOpen(false);
      if (typeof deleted === 'boolean' && deleted) {
        addNotification({
          type: 'success',
          title: t('common:addMember.rm.success.title'),
          description: t('common:addMember.rm.success.description', {
            firstName,
            lastName,
            workspace: workspaceName,
          }),
        });
      }
    },
    [addNotification, firstName, lastName, onDelete, workspaceName],
  );

  function onUpdateComplete(
    updatedRes?: object | TUserMember,
    updated?: boolean,
  ): void {
    if (updated && updatedRes) {
      const res: TUserMember = updatedRes as TUserMember;
      addNotification({
        type: 'success',
        title: t('common:addMember.update.success.title'),
        description: t('common:addMember.update.success.description', {
          firstName: res?.firstName,
          lastName: res?.lastName,
          workspace: workspaceName,
        }),
      });
    }
    onUpdate?.();
    setIsModalEditResourceOpen(false);
  }

  return (
    <div className={styles.memberListItem}>
      <div>
        <Avatar
          name={`${firstName} ${lastName}`}
          image={image?.ref}
          size="large"
        />
      </div>
      <div className={styles.memberListItemContent}>
        <div className={styles.memberListItemNameContainer}>
          <div className={styles.memberListItemName}>
            <div className={styles.memberListItemNameText}>
              <span>{`${firstName} ${lastName}`}</span>
            </div>
            {role === USER_ROLE.OWNER ? (
              <div>
                <Chip type="success" size="small">
                  {t('common:addMember.owner')}
                </Chip>
              </div>
            ) : permission &&
              permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE ? (
              <div>
                <Chip type="neutral" size="small">
                  {t('common:addMember.canEdit')}
                </Chip>
              </div>
            ) : undefined}
          </div>
          {email ? (
            <div className={styles.memberListItemSubName}>{`${email}`}</div>
          ) : workspaceAccess?.hasAccess ? (
            <div className={styles.memberListItemSubName}>
              <div className={styles.invitedEmail}>
                {`${workspaceAccess.email}`}
                <Chip size="small" type="informative">
                  {t('common:addMember.invited')}
                </Chip>
              </div>
            </div>
          ) : undefined}
        </div>
        {resourceId && role !== USER_ROLE.OWNER && (
          <div className={styles.memberListItemTrash}>
            <Button
              icon={IconPencil}
              variant="ghost"
              onClick={() => setIsModalEditResourceOpen(true)}
            />

            {isModalDeleteResourceOpen && (
              <ModalDeleteTeamMember
                isOpen={isModalDeleteResourceOpen}
                resourceId={resourceId}
                key={`delete-resource-${resourceId}`}
                name={`${firstName} ${lastName}`}
                onClose={onCloseDeleteModal}
                onDeleteSuccess={onCloseDeleteModal}
              />
            )}

            {isModalEditResourceOpen && (
              <ModalEditTeamMember
                isOpen={isModalEditResourceOpen}
                resourceId={resourceId}
                key={`delete-resource-${resourceId}`}
                onClose={onUpdateComplete}
                onDelete={() => {
                  setIsModalEditResourceOpen(false);
                  setIsModalDeleteResourceOpen(true);
                }}
                image={image}
                member={{
                  firstName: firstName ?? '',
                  lastName: lastName ?? '',
                  workspaceAccess: workspaceAccess ?? {},
                  capacity: capacity ?? 0,
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
