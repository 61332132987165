import { useContext, useMemo } from 'react';

import { differenceInDays } from 'date-fns';
import { t } from 'i18next';

import { UserContext } from '@/contexts/UserContext';
import { SubscriptionStatusEnum } from '@/types/enums';

import styles from './styles.module.css';
import Chip from '../Chip';
import ProgressBar from '../ProgressBar';

export default function UsageProgress() {
  const {
    usageInfoData: { value, perc, maxProjects, overflow } = {
      value: 0,
      perc: 0,
      maxProjects: 0,
      overflow: false,
    },
    subscription,
  } = useContext(UserContext);

  const title = useMemo(() => {
    switch (subscription?.status) {
      case null:
      case undefined:
        return <h1>Your workspace is on the Free plan</h1>;
      case SubscriptionStatusEnum.active:
        return <h1>Your workspace is on the Pro plan</h1>;
      case SubscriptionStatusEnum.trialing:
        return (
          <>
            <h1>Your workspace is on Free trial</h1>
            <div className={styles.progressTitleRight}>
              <span className={styles.freeTrialLabel}>Free trial</span>
              <Chip size="medium" type="neutral">
                {`${differenceInDays(
                  subscription.billingPeriodEnd!,
                  new Date(),
                )} days left`}
              </Chip>
            </div>
          </>
        );
      case SubscriptionStatusEnum.past_due:
        return <h1>Your workspace is past due</h1>;
      default:
        return undefined;
    }
  }, [subscription]);

  return (
    <div className={styles.container}>
      <div className={styles.usageProgress}>
        <div className={styles.progressTitleContainer}>{title}</div>
        <div className={styles.usageProgressHeading}>
          <span>
            {value} / {maxProjects}
          </span>
          <span>{t('common:profileModal.plans.progress.heading')}</span>
        </div>
        <div className={styles.usageProgressBar}>
          <ProgressBar progress={perc} overflow={overflow} />
        </div>
      </div>
    </div>
  );
}
