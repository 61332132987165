import { useContext, useMemo } from 'react';

import {
  differenceInWeeks,
  endOfDay,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { filter, map } from 'lodash';

import { TTimelineHiddenProject } from '@/types/timeline';

import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import { useProjectHideRowContext } from './Context';
import HiddenBlock from '../HiddenBlock';

export default function Block() {
  const { projects } = useProjectHideRowContext();
  const { virtualizer, weeks } = useContext(TimelineResourcesContext);
  const visibleWeeks = virtualizer.getVirtualItems();
  const visibleBlocks = useMemo(() => {
    const startDate = startOfDay(weeks[visibleWeeks[0].index]);
    const endDate = endOfDay(
      weeks[visibleWeeks[visibleWeeks.length - 1].index],
    );

    return filter(
      projects,
      (block: TTimelineHiddenProject) =>
        isWithinInterval(block.start, {
          start: startDate,
          end: endDate,
        }) ||
        isWithinInterval(block.end, {
          start: startDate,
          end: endDate,
        }) ||
        (isBefore(block.start, startDate) && isAfter(block.end, endDate)),
    );
  }, [projects, visibleWeeks, weeks]);
  return map(visibleBlocks, (block: TTimelineHiddenProject, key: number) => {
    const prevBlock = visibleBlocks[key - 1] ?? block;
    const nextBlock = visibleBlocks[key + 1] ?? block;
    const startMoreThenOneWeek = differenceInWeeks(
      block.start,
      prevBlock?.start,
    );
    const endMoreThenOneWeek = differenceInWeeks(nextBlock?.start, block.start);

    return (
      <HiddenBlock
        key={key}
        block={block}
        hasInitialRadius={key === 0 || startMoreThenOneWeek > 1}
        hasFinalRadius={
          key === visibleBlocks.length - 1 || endMoreThenOneWeek > 1
        }
      />
    );
  });
}
