import React, { useEffect } from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';
type Props = {
  children: React.ReactNode;
  isOpened: boolean;
};

export default function WarningHeader({ children, isOpened = false }: Props) {
  useEffect(() => {
    if (isOpened) {
      document.querySelector('html')?.setAttribute('data-warning', 'true');
    }
    return () => {
      document.querySelector('html')?.removeAttribute('data-warning');
    };
  }, [isOpened]);
  return (
    <div
      key={'warning-header'}
      className={classNames(styles.warningContainer, {
        [styles.open]: isOpened,
      })}
    >
      <div
        className={classNames(styles.content, {
          [styles.open]: isOpened,
        })}
      >
        {children}
      </div>
    </div>
  );
}
