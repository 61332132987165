import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getResources } from '@/services/api/workspace/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TResources } from '@/types/timeline';

export const RESOURCES_QUERY_KEY = 'get-resources';
export default function useResourcesQuery({
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TResources[]>) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery(
    [RESOURCES_QUERY_KEY, workspaceId],
    () => getResources({ workspaceId }),
    {
      enabled,
      onError: (error: TResponseError) => {
        onError(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess,
    },
  );
}
