import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { InfiniteData } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { updateAllocation } from '@/services/api/workspace/resources';
import {
  TAllocationEvent,
  TTimelineAllocationResponse,
  TTimelineProject,
  TTimelineResource,
} from '@/types/timeline';

import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '../projects/useProjectListWithResourcesStatusQuery';
import { TIMELINE_PROJECT_QUERY_KEY } from '../projects/useTimelineProjectsQuery';
import { USAGE_INFO_QUERY_KEY } from '../useUsageInfo';

type Props = {
  resourceId: string;
  projectId: string;
  events: TAllocationEvent[];
  shouldInvalidate?: boolean;
};

type UseAllocationProps = {
  onError?: (error: Error) => void;
};

export default function useAllocation({ onError }: UseAllocationProps = {}) {
  const queryClient = useQueryClient();
  const { workspaceId } = useContext(UserContext);
  // const { addNotification } = useContext(NotificationsContext);

  return useMutation<
    TTimelineAllocationResponse | undefined,
    Error,
    Props,
    {
      currentResourcesData: TTimelineResource[];
      currentTimelineResourcesData: InfiniteData<TTimelineResource[]>;
    }
  >(
    async ({
      resourceId,
      projectId,
      events,
    }: Props): Promise<TTimelineAllocationResponse | undefined> => {
      return await updateAllocation({
        workspaceId,
        resourceId,
        projectId,
        events,
      });
    },

    {
      onMutate: () => {
        const currentResourcesData = queryClient.getQueryData([
          RESOURCES_QUERY_KEY,
          workspaceId,
        ]) as TTimelineResource[];
        const currentTimelineResourcesData = queryClient.getQueryData([
          TIMELINE_RESOURCE_QUERY_KEY,
          workspaceId,
        ]) as InfiniteData<TTimelineResource[]>;
        return {
          currentResourcesData,
          currentTimelineResourcesData,
        };
      },
      onSuccess: (data, _, context) => {
        const currentResourcesData = context?.currentResourcesData;
        const currentTimelineResourcesData =
          context?.currentTimelineResourcesData;
        if (data && currentResourcesData && currentTimelineResourcesData) {
          let resourceToUpdate: TTimelineResource;
          let projectToUpdate: TTimelineProject;
          const updatedResourcesData = currentResourcesData.map((resource) => {
            if (resource.id === data.resourceId) {
              resourceToUpdate = {
                ...resource,
                projects: resource.projects.map((project) => {
                  if (project.id !== data.projectId) {
                    return project;
                  } else {
                    projectToUpdate = {
                      ...project,
                      totalAllocation: data?.totalAllocation ?? 0,
                    };
                    return projectToUpdate;
                  }
                }),
              };
              return resourceToUpdate;
            } else {
              return resource;
            }
          });

          // Find the first page containing the updated project, if any
          const pageToUpdateIndex =
            currentTimelineResourcesData.pages.findIndex((resources) => {
              return resources?.some(
                (resource) =>
                  resource.id === data.resourceId &&
                  resource.projects?.some(
                    (project) => project.id === data.projectId,
                  ),
              );
            });

          // Update timeline paged data adding the received data where is best
          const updatedTimelineResourcesData = {
            ...currentTimelineResourcesData,
            pages: currentTimelineResourcesData.pages.map(
              (resources, index) => {
                if (pageToUpdateIndex < 0 && index === 0) {
                  // Case 1: the updated project can't be found in any page, we will add it to page 0
                  if (
                    resources?.some(
                      (resource) => resource.id === data.resourceId,
                    )
                  ) {
                    // Resource is already available
                    return resources.map((resource) =>
                      resource.id === data.resourceId
                        ? {
                            ...resource,
                            projects: [
                              ...resource.projects,
                              {
                                ...projectToUpdate,
                                timeblocks: data.timeblocks ?? [],
                              },
                            ],
                          }
                        : resource,
                    );
                  } else {
                    // Add resource to page too
                    return [
                      ...resources,
                      {
                        ...resourceToUpdate,
                        projects: [
                          {
                            ...projectToUpdate,
                            timeblocks: data.timeblocks ?? [],
                          },
                        ],
                      },
                    ];
                  }
                } else if (index === pageToUpdateIndex) {
                  // Case 2: we found a page containing the updated project, we'll update its data with the new one
                  return resources.map((resource) =>
                    resource.id === data.resourceId
                      ? {
                          ...resource,
                          projects: resource.projects.map((project) =>
                            project.id === data.projectId
                              ? {
                                  ...project,
                                  timeblocks: data.timeblocks ?? [],
                                  totalAllocation: data.totalAllocation ?? 0,
                                }
                              : project,
                          ),
                        }
                      : resource,
                  );
                } else {
                  // Remove data for the updated project from all other pages to avoid duplicates (this is necessary because we received the full list of timeblock for the project)
                  return resources.map((resource) =>
                    resource.id === data.resourceId
                      ? {
                          ...resource,
                          projects: resource.projects.filter(
                            (project) => project.id !== data.projectId,
                          ),
                        }
                      : resource,
                  );
                }
              },
            ),
          };

          queryClient.setQueryData(
            [RESOURCES_QUERY_KEY, workspaceId],
            updatedResourcesData,
          );
          queryClient.setQueryData(
            [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
            updatedTimelineResourcesData,
          );
          queryClient.invalidateQueries([INSIGHT_PART_QUERY_KEY]);
          queryClient.invalidateQueries([
            PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY,
          ]); // to check if the project is still available
          queryClient.invalidateQueries([TIMELINE_PROJECT_QUERY_KEY]);
          queryClient.invalidateQueries([USAGE_INFO_QUERY_KEY, workspaceId]);
        }
      },
      onError: (error: Error, _, context) => {
        queryClient.setQueryData(
          [RESOURCES_QUERY_KEY, workspaceId],
          cloneDeep(context?.currentResourcesData),
        );
        queryClient.setQueryData(
          [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
          cloneDeep(context?.currentTimelineResourcesData),
        );
        onError?.(error);
        // addNotification({
        //   type: 'error',
        //   title: t('errors:generic.title') as string,
        //   description:
        //     error?.message ?? (t('errors:generic.description') as string),
        // });
      },
    },
  );
}
