import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getProjects } from '@/services/api/workspace/projects';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TTimelineProject } from '@/types/timeline';

export const SEARCH_PROJECTS_KEY = 'get-projects';
export default function useProjectsQuery({
  query,
  enabled,
  resourceId,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TTimelineProject[]> & {
  query: string;
  resourceId: string;
}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery(
    [SEARCH_PROJECTS_KEY, workspaceId, query, resourceId],
    () => getProjects({ resourceId, query, workspaceId }),
    {
      enabled,
      onError: (error: TResponseError) => {
        onError(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess,
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );
}
