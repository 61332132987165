/* eslint-disable import/named */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Button from '@components/Button';
import Toolbar from '@components/Toolbar';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  IconArrowsDiagonal,
  IconArrowsDiagonalMinimize2,
  IconLineHeight,
  IconPlus,
} from '@tabler/icons-react';
import type { VirtualItem } from '@tanstack/react-virtual';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import classNames from 'classnames';
import {
  add,
  differenceInCalendarDays,
  format,
  getDay,
  getWeekOfMonth,
  getWeeksInMonth,
  isBefore,
  isSameWeek,
  setDefaultOptions,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { t } from 'i18next';
import { find } from 'lodash';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useReorderResources from '@/hooks/workspace/resources/useReorderResources';
import { pointerAndClosestCorners, weeksToPixels } from '@/services/helpers';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { ROUTES } from '@/types/routes';
import { TTimelineResource } from '@/types/timeline';

import ModalAddNewTeamMember from '@/components/Modals/ModalAddNewTeamMember';
import Portal from '@/components/Portal';
import SidebarResizer from '@/components/SidebarResizer';
import SkeletonTimelineHeader from '@/components/Skeletons/SkeletonTimelineHeader';
import AccordionRowWrapper from '@/components/Timelines/TimelineResources/AccordionRowWrapper';
import {
  TimelineResourcesContext,
  WEEKS_TO_ADD,
  WORKING_DAYS_IN_A_WEEK,
} from '@/components/Timelines/TimelineResources/context';

import SortableRow from './SortableRow';
import styles from './styles.module.css';
import ManageAutomationsIcon from '../common/ManageAutomationsIcon';

export default function TimelineResources() {
  setDefaultOptions({ weekStartsOn: 1 });

  const todayRef = useRef<HTMLDivElement>(null);
  const previousScrollInPx = useRef(0);
  const isAddingWeeks = useRef(false);

  const {
    resetViewOnToday,
    setResetViewOnToday,
    updateTimeInterval,
    timeInterval,
    weeks,
    virtualizer,
    isLoadingMutation,
    isLoading,
    ref: containerRef,
    sidebarRef,
    compressedByIds,
    setCompressedByIds,
    weekSizeInPx,
    setIsSorting,
    isSorting,
    data,
  } = useContext(TimelineResourcesContext);

  const { layoutIsExpanded, onChangeLayout } = useContext(UIContext);

  const [sortedResources, setSortedResources] = useState<TTimelineResource[]>(
    [],
  );

  const { workspaceId, workspace } = useContext(UserContext);

  const today = startOfToday();
  const currentWeek = startOfWeek(today);
  const weekday = getDay(today) - 1 >= 0 ? getDay(today) - 1 : 6; // We want Monday to equal 0 and Sunday to be 6
  const roundedWeekday = Math.min(weekday, WORKING_DAYS_IN_A_WEEK); // Exclude weekends

  const { mutate: reorderMutation } = useReorderResources();

  const onExpandOrCompressAllFn = useCallback(() => {
    if (compressedByIds?.length) {
      setCompressedByIds([]);
    } else {
      setCompressedByIds(data?.map((item: TTimelineResource) => item.id));
    }
  }, [data, compressedByIds?.length, setCompressedByIds]);

  // add weeks on scroll in both directions
  const handleAddWeekOnScrollFn = useThrottledCallback(
    () => {
      if (containerRef.current && !isAddingWeeks.current && !resetViewOnToday) {
        // get all the values needed to calculate the scroll
        const scrollWidth = virtualizer.getTotalSize();
        const scrollLeft = virtualizer.scrollOffset;
        const direction = virtualizer.scrollDirection;
        const clientWidth = virtualizer.scrollElement?.clientWidth ?? 0;

        // reset the previousScrollInPx to prevent wrong scroll position when scrolling back
        if (direction === 'forward') {
          previousScrollInPx.current = 0;
        }
        // check if the scroll is at the end or the beginning
        if (
          direction === 'forward' &&
          scrollWidth - scrollLeft - clientWidth < clientWidth / 4
        ) {
          isAddingWeeks.current = true;
          updateTimeInterval('after');
        } else if (direction === 'backward' && scrollLeft < clientWidth / 4) {
          isAddingWeeks.current = true;
          // add weeks to add to the previous scroll position to prevent wrong scroll position
          // and set the value to previousScrollInPx that will be used in the useEffect
          previousScrollInPx.current = scrollLeft + WEEKS_TO_ADD * weekSizeInPx;
          updateTimeInterval('before');
        }
      }
    },
    [updateTimeInterval],
    100,
  );

  useEffect(() => {
    if (todayRef.current && resetViewOnToday) {
      todayRef.current.scrollIntoView({
        inline: 'center',
      });
      setResetViewOnToday(false);
    }
  }, [resetViewOnToday, setResetViewOnToday]);

  useEffect(() => {
    // using the previousScrollInPx sets by the handleAddWeekOnScrollFn to prevent wrong scroll position
    if (
      containerRef.current &&
      previousScrollInPx.current !== 0 &&
      isAddingWeeks.current
    ) {
      virtualizer.scrollToOffset(previousScrollInPx.current);
    }
    isAddingWeeks.current = false;
  }, [containerRef, timeInterval, virtualizer]);

  const renderWeekHeader = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const endDate = add(startDate, { days: WORKING_DAYS_IN_A_WEEK - 1 });
      const firstDayOfMonth = startOfMonth(startDate);
      const weekOfMonth = getWeekOfMonth(startDate);
      const weeksInMonth =
        getWeeksInMonth(startDate) -
        (weekOfMonth === 2 && isBefore(firstDayOfMonth, startDate) ? 1 : 0);
      const isFirstWeekOfMonth =
        weekOfMonth === 1 ||
        (weekOfMonth === 2 &&
          differenceInCalendarDays(startDate, firstDayOfMonth) < 7);

      return (
        <Tooltip.Provider key={virtualWeek.key} delayDuration={0}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                className={classNames(styles.weekHeader, {
                  [styles.firstWeekHeader]: isFirstWeekOfMonth,
                })}
                style={{
                  cursor: 'pointer',
                  width: `${virtualWeek.size}px`,
                  transform: `translateX(${virtualWeek.start}px) `,
                }}
              >
                {isFirstWeekOfMonth && (
                  <span
                    className={styles.monthLabel}
                    style={{ width: weeksInMonth * weekSizeInPx }}
                  >
                    {format(startDate, 'MMMM y')}
                  </span>
                )}
                <span
                  className={classNames(styles.weekLabel, {
                    [styles.currentWeek]: isSameWeek(currentWeek, startDate),
                  })}
                >
                  <span>{format(startDate, 'd')}</span>
                  <span>{format(startDate, 'MMM')}</span>
                </span>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal
              container={document.getElementById('radix-tooltip-portal')}
            >
              <Tooltip.Content className="TooltipContent" sideOffset={-15}>
                {`${format(startDate, 'E, dd MMM')} - ${format(
                  endDate,
                  'E, dd MMM',
                )}`}
                <Tooltip.Arrow className="TooltipArrow" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    },
    [currentWeek, weekSizeInPx, weeks],
  );

  const renderWeek = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const firstDayOfMonth = startOfMonth(startDate);
      const weekOfMonth = getWeekOfMonth(startDate);
      const isFirstWeekOfMonth =
        weekOfMonth === 1 ||
        (weekOfMonth === 2 &&
          differenceInCalendarDays(startDate, firstDayOfMonth) < 7);

      return (
        <div
          key={virtualWeek.key}
          className={classNames(styles.week, {
            [styles.firstWeek]: isFirstWeekOfMonth,
          })}
          style={{
            width: `${virtualWeek.size}px`,
            transform: `translateX(${virtualWeek.start}px) `,
          }}
        />
      );
    },
    [weeks],
  );

  const [activeResourceSortingId, setActiveResourceSortingId] =
    useState<UniqueIdentifier | null>(null);

  useEffect(() => {
    setSortedResources(data);
  }, [data]);

  const onDragStartFn = ({ active }: DragStartEvent) => {
    setActiveResourceSortingId(active?.id);
    setIsSorting(true);
  };

  const stopScrolling = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsSorting(false);
        setActiveResourceSortingId(null);
      }
    },
    [setIsSorting],
  );

  useEffect(() => {
    if (isSorting) {
      window.addEventListener('keydown', stopScrolling);
      return () => {
        window.removeEventListener('keydown', stopScrolling);
      };
    }
  }, [isSorting, stopScrolling]);

  const onDragEndFn = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = sortedResources.findIndex(
          (resource) => resource.id === active.id,
        );
        const newIndex = sortedResources.findIndex(
          (resource) => resource.id === over?.id,
        );
        const newItemsArray = arrayMove(sortedResources, oldIndex, newIndex);
        setSortedResources(newItemsArray);
        reorderMutation({
          newOrder: newItemsArray?.map((resource) => resource.id),
        });
      }
      setIsSorting(false);
      setActiveResourceSortingId(null);
    },
    [setIsSorting, sortedResources, reorderMutation],
  );

  const renderTimelineResourceRow = (resource: TTimelineResource) => {
    return (
      <SortableRow
        activeSortingId={activeResourceSortingId}
        resource={resource}
        key={resource?.id}
      />
    );
  };

  const [addPersonModalOpen, setAddPersonModalOpen] = useState(false);
  const onCloseAddPersonModal = useCallback(
    () => setAddPersonModalOpen(false),
    [],
  );

  const onOpenAddPersonModal = useCallback(
    () => setAddPersonModalOpen(true),
    [],
  );

  const currentDraggedProject = useMemo(
    () =>
      find(sortedResources, {
        id: activeResourceSortingId,
      }) as TTimelineResource,
    [activeResourceSortingId, sortedResources],
  );

  return (
    <div
      key={workspace?.id}
      ref={containerRef}
      style={{
        pointerEvents: isLoadingMutation ? 'none' : 'auto',
      }}
      className={styles.wrapper}
      onScroll={handleAddWeekOnScrollFn}
    >
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div className={styles.floatingButton}>
              <Toolbar>
                <Button
                  icon={
                    layoutIsExpanded
                      ? IconArrowsDiagonalMinimize2
                      : IconArrowsDiagonal
                  }
                  variant="ghost"
                  size="large"
                  onClick={onChangeLayout}
                />
              </Toolbar>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Portal
            container={document.getElementById('radix-tooltip-portal')}
          >
            <Tooltip.Content className="TooltipContent" sideOffset={5}>
              {layoutIsExpanded ? t('common:compress') : t('common:expand')}
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <div
        className={styles.container}
        style={{ width: `${virtualizer.getTotalSize()}px` }}
      >
        <SidebarResizer />

        <div className={styles.sidebar} ref={sidebarRef}>
          {!data?.length && !isLoading ? (
            <div className={styles.emptyState}>
              {t('timeline:emptyStateResources')}
            </div>
          ) : !data?.length && isLoading ? (
            Array.from({ length: 8 }).map((_, index) => (
              <SkeletonTimelineHeader
                isExpanded={layoutIsExpanded}
                key={index}
              />
            ))
          ) : null}
        </div>
        <div className={styles.filters}>
          <div className={styles.filtersWrapper}>
            <div className={styles.filterLeft}>
              <Button
                label={t('timeline:ctaPeopleLabel')}
                size="small"
                variant="ghost"
                link={ROUTES.DASHBOARD.replace(
                  ':workspaceId',
                  workspaceId ?? '',
                )}
                active
              />
              <Button
                label={t('timeline:ctaProjectsLabel')}
                size="small"
                variant="ghost"
                link={ROUTES.PROJECT.replace(':workspaceId', workspaceId ?? '')}
              />
            </div>
            <div className={styles.rightHeaderButton}>
              {workspace?.permission ===
                WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
                <ManageAutomationsIcon
                  autoHide={workspace?.autoHide ?? false}
                  autoSync={workspace?.autoSync ?? false}
                />
              )}
              <Tooltip.Provider delayDuration={1000}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <span>
                      <Button
                        icon={IconLineHeight}
                        variant="ghost"
                        size="small"
                        onClick={onExpandOrCompressAllFn}
                      />
                    </span>
                  </Tooltip.Trigger>
                  <Tooltip.Portal
                    container={document.getElementById('radix-tooltip-portal')}
                  >
                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                      {t('timeline:expandAllTooltip') as string}
                      <Tooltip.Arrow className="TooltipArrow" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </div>
          </div>
        </div>
        {workspace?.permission ===
          WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
          <div className={styles.addPerson}>
            <button onClick={onOpenAddPersonModal}>
              <IconPlus size={16} /> {t('timeline:addTeamCtaLabel')}
            </button>
            {addPersonModalOpen && (
              <ModalAddNewTeamMember
                isOpen={addPersonModalOpen}
                onClose={onCloseAddPersonModal}
              />
            )}
          </div>
        )}
        <div className={styles.header}>
          {virtualizer.getVirtualItems().map(renderWeekHeader)}
        </div>
        <div className={styles.grid}>
          {virtualizer.getVirtualItems().map(renderWeek)}
          <div
            ref={todayRef}
            className={styles.today}
            style={{
              left:
                weeksToPixels(
                  currentWeek,
                  timeInterval.start,
                  false,
                  weekSizeInPx,
                ) +
                roundedWeekday * (weekSizeInPx / WORKING_DAYS_IN_A_WEEK),
            }}
          />
        </div>
        <DndContext
          onDragStart={onDragStartFn}
          onDragEnd={onDragEndFn}
          autoScroll={{
            threshold: { x: -110, y: 0.25 },
          }}
          collisionDetection={pointerAndClosestCorners}
          modifiers={[restrictToParentElement, restrictToVerticalAxis]}
        >
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={sortedResources}
          >
            <div
              className={classNames(styles.content, {
                [styles.noPaddingBottom]:
                  workspace?.permission !==
                  WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
              })}
            >
              {sortedResources?.map(renderTimelineResourceRow)}
            </div>
          </SortableContext>
          {activeResourceSortingId && (
            <Portal>
              <DragOverlay
                adjustScale={false}
                dropAnimation={{
                  sideEffects: defaultDropAnimationSideEffects({
                    styles: { active: { visibility: 'hidden' } },
                  }),
                }}
              >
                <AccordionRowWrapper
                  key={activeResourceSortingId}
                  isDraggableOverlay
                  resource={currentDraggedProject}
                />
              </DragOverlay>
            </Portal>
          )}
        </DndContext>
      </div>
    </div>
  );
}
