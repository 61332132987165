import { useCallback, useContext, useEffect, useState } from 'react';

import type { Paddle, PaddleEventData } from '@paddle/paddle-js';
import { initializePaddle } from '@paddle/paddle-js';

import { UserContext } from '@/contexts/UserContext';

import useCreateCustomer from '../workspace/useCreateCustomer';
import useUpdateCustomer from '../workspace/useUpdateCustomer';

type Props = {
  enableCallback?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  eventHandler?: (event: PaddleEventData) => void;
};

export default function usePaddle({
  enableCallback = true,
  onOpen,
  onClose,
  eventHandler,
}: Props = {}) {
  const { workspace } = useContext(UserContext);
  const [paddle, setPaddle] = useState<Paddle | undefined>();
  const { mutate: createCustomer } = useCreateCustomer();
  const { mutate: updateCustomer } = useUpdateCustomer();

  // TODO: test this function with paddle notifications
  const createOrUpdateCustomer = useCallback(
    (event: PaddleEventData) => {
      switch (event.name) {
        case 'checkout.loaded':
          onOpen?.();
          break;
        case 'checkout.closed':
          onClose?.();
          break;
        case 'checkout.customer.created':
        case 'checkout.customer.updated':
          if (event.data?.customer.id) {
            const {
              customer: { id: c_id, address, business },
            } = event.data;

            const existingCust = workspace?.customer?.customerId === c_id;

            if (!existingCust) {
              createCustomer?.({
                customerId: event.data.customer.id,
                addressId: event.data?.customer?.address?.id,
                businessId: event.data?.customer?.business?.id,
              });
            } else if (
              workspace.customer.addressId !== address?.id ||
              workspace.customer.businessId !== business?.id
            ) {
              updateCustomer?.({
                customerId: event.data.customer.id,
                addressId: event.data.customer?.address?.id,
                businessId: event.data.customer?.business?.id,
              });
            }
          }
      }
    },
    [
      createCustomer,
      onClose,
      onOpen,
      updateCustomer,
      workspace?.customer?.addressId,
      workspace?.customer?.businessId,
      workspace?.customer?.customerId,
    ],
  );

  useEffect(() => {
    if (!paddle?.Initialized) {
      const paddleEnv =
        import.meta.env.VITE_PADDLE_ENV === 'production'
          ? 'production'
          : 'sandbox';
      initializePaddle({
        token: import.meta.env.VITE_PADDLE_PUBLIC_KEY,
        environment: paddleEnv,
        checkout: {
          settings: {
            displayMode: 'overlay',
          },
        },
      }).then((paddle) => setPaddle(paddle));
    }
  }, [paddle?.Initialized]);

  useEffect(() => {
    if (paddle && paddle.Initialized && enableCallback)
      paddle.Update({
        eventCallback: eventHandler ?? createOrUpdateCustomer,
      });
  }, [createOrUpdateCustomer, enableCallback, eventHandler, paddle]);

  return { paddle };
}
