import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { verifyConsent } from '@/services/api/account';

import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';
export default function useVerifyConsent() {
  const { addNotification } = useContext(NotificationsContext);

  const queryClient = useQueryClient();

  return useMutation<void, Error, void>(
    async (): Promise<void> => {
      await verifyConsent();
    },
    {
      onError: (error: Error) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess: () => queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]),
    },
  );
}
