import { useCallback, useMemo, useRef, useState } from 'react';

import {
  IconPlus,
  IconSquareRoundedPlus,
  IconUserSquareRounded,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Item, Menu, RightSlot, contextMenu } from 'react-contexify-moze';
import { useHotkeys, type Options } from 'react-hotkeys-hook';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { GLOBAL_SCOPE } from '@/types/constants';

import Button from '@/components/Button';
import ModalAddNewProject from '@/components/Modals/ModalAddNewProject';
import ModalAddNewTeamMember from '@/components/Modals/ModalAddNewTeamMember';
import { ContentPages } from '@/components/Modals/ModalUserProfile';
import Portal from '@/components/Portal';

import styles from './styles.module.css';

const MENU_ID = 'add-button-context';
export default function AddResourceProjectButton() {
  const addButtonTriggerRef = useRef<HTMLButtonElement>(null);
  const [addMenuIsOpen, setAddMenuIsOpen] = useState(false);

  const [modalSelected, setModalSelected] = useState<
    'project' | 'person' | undefined
  >(undefined);

  const { openModal } = useProfileModal();

  function getMenuPosition() {
    const { left, bottom, width } =
      addButtonTriggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    return { x: left - (208 - width), y: bottom + 8 };
  }

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!addMenuIsOpen)
        contextMenu.show({
          id: MENU_ID,
          event: e,
          position: getMenuPosition(),
        });
      else contextMenu.hideAll();
    },
    [addMenuIsOpen],
  );

  const openProjectModal = useCallback(() => {
    if (addMenuIsOpen) contextMenu.hideAll();
    setModalSelected('project');
  }, [addMenuIsOpen]);

  const openPersonModal = useCallback(() => {
    if (addMenuIsOpen) contextMenu.hideAll();
    setModalSelected('person');
  }, [addMenuIsOpen]);

  const keyOptions: Options = useMemo(
    () =>
      ({
        preventDefault: true,
        scopes: GLOBAL_SCOPE,
      }) as Options,
    [],
  );

  const openProfileModal = useCallback(() => {
    contextMenu.hideAll();

    openModal(ContentPages.PROFILE_INFO);
  }, [openModal]);

  useHotkeys('c', openProjectModal, keyOptions, [openProjectModal]);
  useHotkeys('shift+c', openPersonModal, keyOptions, [openPersonModal]);
  useHotkeys('p', openProfileModal, keyOptions, [openProfileModal]);

  return (
    <>
      <Button
        ref={addButtonTriggerRef}
        icon={IconPlus}
        onClick={onClickFn}
        variant="outline"
        size="medium"
        active={addMenuIsOpen}
      />

      <Portal>
        <Menu
          id={MENU_ID}
          animation={false}
          onVisibilityChange={setAddMenuIsOpen}
          className={classNames('is-right', styles.addMenu)}
        >
          <Item onClick={openProjectModal}>
            <IconSquareRoundedPlus size={20} style={{ marginRight: 8 }} />
            {t('common:addProjectContextMenuActions.addProject')}
            <RightSlot>C</RightSlot>
          </Item>
          <Item onClick={openPersonModal}>
            <IconUserSquareRounded size={20} style={{ marginRight: 8 }} />
            {t('common:addResourceContextMenuActions.addResource')}
            <RightSlot>{'⇧C'}</RightSlot>
          </Item>
        </Menu>
      </Portal>
      {modalSelected === 'project' && (
        <ModalAddNewProject
          canAddPerson
          onClose={() => setModalSelected(undefined)}
          isOpen={true}
        />
      )}
      {modalSelected === 'person' && (
        <ModalAddNewTeamMember
          isOpen={true}
          onClose={() => setModalSelected(undefined)}
        />
      )}
    </>
  );
}
