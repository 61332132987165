import React, { useCallback, useContext, useMemo, useState } from 'react';

import { t } from 'i18next';

import { UserContext } from '@/contexts/UserContext';
import useUpdateAccountSettings from '@/hooks/account/useUpdateAccountSettings';
import { useAnalytics } from '@/hooks/utils/useAnalytics';

import styles from './styles.module.css';
import Section from '../../../Section';
import Switch from '../../../Switch';

export default function ModalNotificationContent() {
  const { account } = useContext(UserContext);

  const notificationEnabledObject = useMemo(() => {
    return (
      account?.workspaces?.reduce((acc, { id, weeklyEmail }) => {
        return {
          ...acc,
          [id]: weeklyEmail,
        };
      }, {}) ?? {}
    );
  }, [account]);

  const [notificationEnabled, setNotificationEnabled] = useState<
    Record<string, boolean>
  >(notificationEnabledObject);

  const { mutate } = useUpdateAccountSettings();
  const { trackEvent } = useAnalytics();
  const handleSave = useCallback(
    async (workspaceId: string, checked: boolean) => {
      setNotificationEnabled((prevState) => ({
        ...prevState,
        [workspaceId]: checked,
      }));
      mutate(
        { weeklyEmail: checked, workspaceId },
        {
          onSuccess: () => {
            trackEvent(
              checked ? 'WEEKLY_EMAIL_ACTIVATED' : 'WEEKLY_EMAIL_DEACTIVATED',
              workspaceId,
            );
          },
          onError: () =>
            setNotificationEnabled((prevState) => ({
              ...prevState,
              [workspaceId]: !checked,
            })),
        },
      );
    },
    [mutate, trackEvent],
  );

  return (
    <React.Fragment>
      <Section title={t('common:profileModal.notifications.title')}>
        {account?.workspaces?.map((workspace, index) => (
          <div key={index} className={styles.mainContainer}>
            <div className={styles.textContainer}>
              <h4>{workspace?.name}</h4>
              <p>
                {t(
                  'common:profileModal.notifications.weeklyInsightsDescription',
                )}
              </p>
            </div>
            <div className={styles.switchContainer}>
              <Switch
                id={'notification-weekly-insights'}
                label={''}
                checked={notificationEnabled?.[workspace?.id] ?? false}
                onChange={(checked) => handleSave(workspace?.id, checked)}
              />
            </div>
          </div>
        ))}
      </Section>
    </React.Fragment>
  );
}
