import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';

import Item from './Item';
import Label from './Label';
import Separator from './Separator';
import styles from './styles.module.css';

type SelectDropdownProps = {
  label: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  align?: 'start' | 'end';
  size?: 'medium' | 'large';
  minWidth?: number;
  variant?: 'default' | 'simple';
  className?: string;
  children?: React.ReactNode;
};

const SelectDropdown = ({
  label,
  placeholder,
  disabled = false,
  align = 'start',
  size = 'medium',
  minWidth,
  variant = 'default',
  className = '',
  children,
}: SelectDropdownProps) => {
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger
        disabled={disabled}
        className={classNames(
          styles.trigger,
          styles[size],
          styles[variant],
          { [styles.placeholder]: !label },
          className,
        )}
      >
        <span>{label || placeholder || 'Select...'}</span>
        <IconChevronDown size={20} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align={align}
          sideOffset={4}
          className={styles.content}
          style={{ minWidth }}
        >
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

SelectDropdown.Item = Item;
SelectDropdown.Separator = Separator;
SelectDropdown.Label = Label;

export default SelectDropdown;
