import { useContext, useMemo } from 'react';

import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { filter, map } from 'lodash';
import { isMobile } from 'react-device-detect';

import {
  TProjectListWithResources,
  TResourceItemList,
  TTimeBlockRange,
} from '@/types/timeline';

import { useResourceRowContext } from './Context';
import BlockWrap from '../BlockWrap';
import { TimelineProjectsContext } from '../context';

export default function Blocks() {
  const { resource, project, onActiveBlockFn, rowDisabled } =
    useResourceRowContext();
  const { virtualizer, weeks, activeBlockIds, selectedBlockId } = useContext(
    TimelineProjectsContext,
  );
  const visibleWeeks = virtualizer?.getVirtualItems();

  const visibleBlocks = useMemo(() => {
    const startDate = startOfDay(weeks[visibleWeeks[0].index]);
    const endDate = endOfDay(
      weeks[visibleWeeks[visibleWeeks.length - 1].index],
    );
    return filter(
      resource?.timeblocks,
      (block: TTimeBlockRange) =>
        isWithinInterval(block.start, {
          start: startDate,
          end: endDate,
        }) ||
        isWithinInterval(block.end, {
          start: startDate,
          end: endDate,
        }) ||
        (isBefore(block.start, startDate) && isAfter(block.end, endDate)) ||
        (selectedBlockId && selectedBlockId === block.id),
    );
  }, [resource?.timeblocks, visibleWeeks, weeks, selectedBlockId]);

  return map(visibleBlocks, (block: TTimeBlockRange) => {
    const id = `${resource?.id}-${resource?.id}-${format(
      block.start,
      'yyyy-MM-dd',
    )}-${format(block.end, 'yyyy-MM-dd')}`;

    return (
      <BlockWrap
        key={block.id}
        currentProject={project as TProjectListWithResources<TResourceItemList>}
        projectColor={project?.color ?? ''}
        isActive={activeBlockIds?.includes(id)}
        onClick={onActiveBlockFn}
        resourceId={resource?.id ?? ''}
        projectId={project?.id ?? ''}
        projectStatus={project?.status}
        resourceName={`${resource?.firstName} ${resource?.lastName}`}
        avatar={resource?.image}
        block={block}
      >
        {!rowDisabled && <BlockWrap.Handle type="left" />}
        {!isMobile && <BlockWrap.Tooltip />}
        <BlockWrap.Content />
        {!rowDisabled && <BlockWrap.Handle type="right" />}
      </BlockWrap>
    );
  });
}
