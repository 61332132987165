import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Button from '@components/Button';
import { t } from 'i18next';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';

import { UserContext } from '@/contexts/UserContext';
import useCanDeleteAccount from '@/hooks/account/useCanDeleteAccount';
import useUpdateAccountRole, {
  UserAccountRoleUpdate,
} from '@/hooks/account/useUpdateAccountRole';
import { titleCase } from '@/services/helpers';
import { TOwnedWorkspaceInfo } from '@/types/account';
import { USER_ROLE } from '@/types/enums';

import Avatar from '@/components/Avatar';
import SelectDropdown from '@/components/SelectDropdown';

import styles from './styles.module.css';
import ModalWarning from '../ModalWarning';

const MODAL_SCOPE = 'ModalCannotDeleteAccount-scope';

type Props = {
  isOpen: boolean;

  onClose: () => void;
};

function WorkspaceItem({
  workspace,
  onChange,
}: {
  onChange?: (workspaceId: string, accountId: string) => void;
  workspace: TOwnedWorkspaceInfo;
}) {
  const [accountId, setResourceId] = useState<string | null>(
    workspace?.members?.[0]?.accountId ?? null,
  );

  useEffect(() => {
    if (workspace.id && accountId && onChange) {
      onChange?.(workspace.id, accountId);
    }
  }, [workspace.id, accountId, onChange]);

  const resource = useMemo(() => {
    return workspace.members?.find((res) => res.accountId === accountId);
  }, [accountId, workspace.members]);
  return (
    <div className={styles.workspaceItem} key={workspace.id}>
      <span className={styles.wsItemLabel}>{workspace.name}</span>
      <div className={styles.dropdownContainer}>
        <SelectDropdown
          label={
            titleCase(`${resource?.firstName} ${resource?.lastName}`) as string
          }
        >
          {workspace.members?.map((res) => (
            <SelectDropdown.Item
              checked={accountId === res.accountId}
              key={res.accountId}
              onSelect={() => {
                setResourceId(res.accountId);
              }}
              label={
                <span className={styles.resourceItem}>
                  <Avatar
                    name={`${res.firstName} ${res.lastName}`}
                    image={res.image?.ref}
                    size="medium"
                  />
                  <span className={styles.resourceName}>
                    {titleCase(`${res.firstName} ${res.lastName}`) ?? ''}
                  </span>
                </span>
              }
            />
          ))}
        </SelectDropdown>
      </div>
    </div>
  );
}

export default function ModalCannotDeleteAccount({ isOpen, onClose }: Props) {
  const { account } = useContext(UserContext);
  const { data: canDeleteAccount, isLoading } = useCanDeleteAccount({
    enabled: !!account,
  });
  const { disableScope, enableScope } = useHotkeysContext();

  useEffect(() => {
    if (isOpen) enableScope(MODAL_SCOPE);
    else disableScope(MODAL_SCOPE);
    return () => disableScope(MODAL_SCOPE);
  }, [disableScope, enableScope, isOpen]);

  const [owners, setOwners] = useState<Record<string, string>>({});
  const { mutate: updateAccountRole } = useUpdateAccountRole();

  const onUpdateAccountRole = useCallback(() => {
    const opt: UserAccountRoleUpdate[] = Object.entries(owners).map(
      ([workspaceId, accountId]) =>
        ({
          workspaceId,
          accountId,
          role: USER_ROLE.OWNER,
        }) as UserAccountRoleUpdate,
    );
    updateAccountRole(opt);
  }, [owners, updateAccountRole]);

  useHotkeys(
    'enter',
    () =>
      owners &&
      Object.values(owners)?.length > 0 &&
      !isLoading &&
      onUpdateAccountRole(),
    { scopes: MODAL_SCOPE, enableOnFormTags: true, preventDefault: true },
    [owners, isLoading, onUpdateAccountRole],
  );

  const onChangeResourceOwner = useCallback((wsId: string, resId: string) => {
    setOwners((prev) => ({ ...prev, [wsId]: resId }));
  }, []);

  const workspaceWithoutOwners = useMemo(() => {
    return canDeleteAccount?.workspaces?.filter(
      (ws) => !ws.owners?.length && !!ws.members?.length,
    );
  }, [canDeleteAccount?.workspaces]);

  const workspaceWithActiveSubs = useMemo(() => {
    return canDeleteAccount?.workspaces?.filter(
      (ws) => !ws.owners?.length && !!ws.subscription?.id,
    );
  }, [canDeleteAccount?.workspaces]);

  const { title, text, content, confirmButton } = useMemo(() => {
    if (workspaceWithoutOwners?.length) {
      return {
        title: 'Set an owner',
        confirmButton: (
          <Button
            label="Continue"
            onClick={() => {
              onUpdateAccountRole();
            }}
            isLoading={isLoading}
            disabled={!Object.values(owners)?.length}
            size="medium"
          />
        ),
        text: 'Before proceeding to remove your account, promote another member to be the owner of your workspaces.',
        content: (
          <div className={styles.workspaceList}>
            {workspaceWithoutOwners?.map((workspace) => (
              <WorkspaceItem
                workspace={workspace}
                key={workspace.id}
                onChange={onChangeResourceOwner}
              />
            ))}
          </div>
        ),
      };
    } else if (workspaceWithActiveSubs?.length) {
      return {
        title: 'Cancel the subscription',
        text: undefined,
        confirmButton: (
          <Button
            label="Ok, thanks"
            onClick={onClose}
            isLoading={isLoading}
            variant="outline"
            size="medium"
          />
        ),
        content: (
          <p className={styles.actionLabel}>
            Before proceeding deleting your account, please cancel your active
            subscription on{' '}
            {workspaceWithActiveSubs.map((ws) => ws.name).join(', ')} workspace
            {workspaceWithActiveSubs.length > 1 ? 's' : ''}.
          </p>
        ),
      };
    }
    return {
      text: '',
      content: undefined,
    };
  }, [
    isLoading,
    onChangeResourceOwner,
    onClose,
    onUpdateAccountRole,
    owners,
    workspaceWithActiveSubs,
    workspaceWithoutOwners,
  ]);

  return (
    <ModalWarning
      isOpen={isOpen}
      modalKey={`modal-cannot-delete-my-account`}
      onClose={onClose}
      warningDescription={text}
      title={title}
      footer={
        <>
          {confirmButton}
          <Button
            label={t(
              `common:${workspaceWithoutOwners?.length ? 'cancel' : 'close'}`,
            )}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      {content}
    </ModalWarning>
  );
}
