import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import { addWeeks, endOfWeek, isWithinInterval } from 'date-fns';
import { find, some } from 'lodash';
import { isMobile } from 'react-device-detect';

import { useEventEmitter } from '@/contexts/EventEmitterContext';
import { UIContext } from '@/contexts/UIContext';
import { generateUUID } from '@/services/helpers';
import { updateBlocksWithProperEvents } from '@/services/helpers/timelines/resources';
import { ALLOCATION_EVENT_OPERATION_TYPE } from '@/types/enums';
import { TTimeBlockRange } from '@/types/timeline';

import { useResourceRowContext } from './Context';
import styles from './styles.module.css';
import ScrollDraggingOverlay from '../../TimelineProjects/DraggingOverlay';
import { ProjectStatusContext } from '../ProjectStatusWrapper/ProjectStatusContext';
import { TimelineProjectsContext } from '../context';

export default function Content({ children }: PropsWithChildren) {
  const {
    mouseEventRef,
    setNewBlock,
    setHoverWeek,
    onActiveBlockFn,
    resource,
    project,
    rowDisabled,
  } = useResourceRowContext();
  const { setBlocksToUpdate } = useContext(ProjectStatusContext);

  const { currentScrollSize, timeInterval, weekSizeInPx, activeBlockIds } =
    useContext(TimelineProjectsContext);

  const { next } = useEventEmitter<Date | undefined>('hoverWeek');
  const { layoutIsExpanded } = useContext(UIContext);
  const [isDragging, setIsDragging] = useState(false);

  const currentTimeIntervalStart = useRef<Date | null>(null);

  const handleDragging = useCallback(() => {
    if (rowDisabled) return;
    if (isDragging) {
      // Click & drag creation of a new block
      const mousePosition =
        (mouseEventRef.current?.mouseMoveX ?? 0) + currentScrollSize;
      if (mouseEventRef.current.mouseDownX) {
        const startPosition = Math.min(
          mouseEventRef.current.mouseDownX,
          mousePosition,
        );
        const endPosition = Math.max(
          mouseEventRef.current.mouseDownX,
          mousePosition,
        );
        // Snap
        const startWeek = addWeeks(
          timeInterval.start,
          startPosition / weekSizeInPx,
        );
        const endWeek = endOfWeek(
          addWeeks(
            currentTimeIntervalStart.current ?? timeInterval.start,
            endPosition / weekSizeInPx,
          ),
        );
        // Create new block
        mouseEventRef.current.newInterval = {
          start: startWeek,
          end: endWeek,
        };
        if (mouseEventRef.current.newInterval) {
          setNewBlock({
            ...mouseEventRef.current.newInterval,
            id: 'new',
            allocation: 1,
          });
        }
      }
    }
  }, [
    currentScrollSize,
    isDragging,
    mouseEventRef,
    rowDisabled,
    setNewBlock,
    timeInterval.start,
    weekSizeInPx,
  ]);
  const handleMouseDownFn = useCallback(
    (event: React.MouseEvent, resourceId: string) => {
      if (rowDisabled || event.button !== 0) return;
      const mousePosition = event.clientX + currentScrollSize;

      // Snap
      const startWeek = addWeeks(
        timeInterval.start,
        mousePosition / weekSizeInPx,
      );
      const endWeek = endOfWeek(startWeek);
      const slotHasBlock = project?.resources.some(
        (resource) =>
          resource.id === resourceId &&
          some(resource.timeblocks, (block) =>
            isWithinInterval(startWeek, {
              start: block.start,
              end: block.end,
            }),
          ),
      );

      if (!slotHasBlock && activeBlockIds?.length === 0) {
        event.stopPropagation();
        mouseEventRef.current = {
          ...mouseEventRef.current,
          mouseDownX: event.clientX + currentScrollSize,
          mouseMoveX: event.clientX,
          resourceId,
          newInterval: {
            start: startWeek,
            end: endWeek,
          },
        };
        setNewBlock({
          id: 'new',
          allocation: 1,
          start: startWeek,
          end: endWeek,
        });
      }
      currentTimeIntervalStart.current = timeInterval.start;
      setIsDragging(true);
    },
    [
      activeBlockIds?.length,
      currentScrollSize,
      mouseEventRef,
      project?.resources,
      rowDisabled,
      setNewBlock,
      timeInterval.start,
      weekSizeInPx,
    ],
  );

  const handleMoveAndDrag = useCallback(
    (e: { clientX: number }) => {
      if (isMobile) return;
      if (mouseEventRef.current) mouseEventRef.current.mouseMoveX = e.clientX;
      handleDragging();
    },
    [handleDragging, mouseEventRef],
  );

  const handleMouseMoveFn = useCallback(
    (event: React.MouseEvent) => {
      if (isMobile) return;

      // determine the week that the mouse is hovering
      const mousePosition = event.clientX + currentScrollSize;
      if (mouseEventRef.current)
        mouseEventRef.current.mouseMoveX = event.clientX;
      // Simple hovering
      const addedWeek = addWeeks(
        timeInterval.start,
        mousePosition / weekSizeInPx,
      );
      setHoverWeek(addedWeek);
      next?.(addedWeek);
    },
    [
      currentScrollSize,
      mouseEventRef,
      next,
      setHoverWeek,
      timeInterval.start,
      weekSizeInPx,
    ],
  );

  const handleMouseExitFn = useCallback(() => {
    if (isMobile) return;

    next?.(undefined);
  }, [next]);

  const handleMouseUpFn = useCallback(() => {
    if (isMobile) return;

    if (mouseEventRef?.current?.newInterval) {
      const id = generateUUID();
      const newBlock = {
        ...mouseEventRef?.current?.newInterval,
        id,
        allocation: 1,
        operation: ALLOCATION_EVENT_OPERATION_TYPE.INSERT,
      } as TTimeBlockRange;

      if (mouseEventRef?.current?.resourceId) {
        onActiveBlockFn({
          blockId: newBlock?.id,
          isMulti: false,
          resourceId: mouseEventRef?.current?.resourceId,
          projectId: project?.id ?? '',
        });
        const currentResources = find(project?.resources, {
          id: mouseEventRef?.current?.resourceId,
        });

        const blocksToUpdate = [
          ...updateBlocksWithProperEvents(
            currentResources?.timeblocks || [],
            newBlock,
          ),
          newBlock,
        ];

        setBlocksToUpdate({
          events: blocksToUpdate,
          resourceId: mouseEventRef?.current?.resourceId,
          projectId: project?.id ?? '',
          shouldInvalidated: true,
        });
      }
    }
    mouseEventRef.current = {};
    setNewBlock(null);
    currentTimeIntervalStart.current = null;
    setIsDragging(false);
  }, [
    mouseEventRef,
    onActiveBlockFn,
    project?.id,
    project?.resources,
    setBlocksToUpdate,
    setNewBlock,
  ]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mouseup', handleMouseUpFn);
      document.addEventListener('contextmenu', handleMouseUpFn);
      document.addEventListener('mousemove', handleMoveAndDrag);
    }
    return () => {
      document.removeEventListener('mouseup', handleMouseUpFn);
      document.removeEventListener('contextmenu', handleMouseUpFn);
      document.removeEventListener('mousemove', handleMoveAndDrag);
    };
  }, [handleMouseUpFn, handleDragging, isDragging, handleMoveAndDrag]);

  return (
    <>
      <div
        style={{ zIndex: 1 }}
        className={classNames(styles.content, {
          [styles.isExpanded]: layoutIsExpanded,
        })}
        onMouseMove={handleMouseMoveFn}
        onMouseLeave={handleMouseExitFn}
        onMouseDown={(event) => handleMouseDownFn(event, resource?.id ?? '')}
        aria-hidden
      >
        {children}
      </div>
      {isDragging && (
        <ScrollDraggingOverlay
          panelId="resource-row"
          onScrollUpdate={handleDragging}
          stepSize={weekSizeInPx}
        />
      )}
    </>
  );
}
