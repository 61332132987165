/* eslint-disable import/named */
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  DraggableAttributes,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as Tooltip from '@radix-ui/react-tooltip';
import type { VirtualItem } from '@tanstack/react-virtual';
import classNames from 'classnames';
import { isWithinInterval, setDefaultOptions } from 'date-fns';
import { t } from 'i18next';
import { xor } from 'lodash';

import { useEventEmitter } from '@/contexts/EventEmitterContext';
import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import {
  PROJECT_TYPE,
  WORKSPACE_MEMBER_PERMISSION,
  WORKSPACE_MODE,
} from '@/types/enums';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';

import AssignResourceButtonDropdown from '@/components/AssignResourceButtonDropdown';
import ProjectHeader from '@/components/Timelines/TimelineProjects/ProjectHeader';
import { TimelineProjectsContext } from '@/components/Timelines/TimelineProjects/context';

import RecapBlock from './RecapBlock';
import styles from './styles.module.css';
import SortableResource from '../SortableResource';

type Props = {
  project: TProjectListWithResources<TResourceItemList>;
  isDraggableOverlay?: boolean;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function AccordionRowWrapper({
  project,
  sortHandlerProps,
  isDraggableOverlay,
}: Props) {
  setDefaultOptions({ weekStartsOn: 1 });
  const [hoverWeek, setHoverWeek] = useState<Date | undefined>(undefined);
  const {
    virtualizer,
    weeks,
    activeBlockIds,
    setActiveProjectId,
    setExpandedByIds,
    expandedByIds,
    setActiveResourceId,
    setIsSorting,
  } = useContext(TimelineProjectsContext);

  const { layoutIsExpanded } = useContext(UIContext);

  useEffect(() => {
    if (activeBlockIds.length === 0) {
      setActiveProjectId(null);
      setActiveResourceId(null);
    }
  }, [activeBlockIds.length, setActiveProjectId, setActiveResourceId]);

  const {
    workspace: { mode, permission } = {
      mode: WORKSPACE_MODE.DAYS,
      permission: undefined,
    },
  } = useContext(UserContext);

  const [activeResourceSortingId, setActiveResourceSortingId] =
    useState<UniqueIdentifier | null>(null);
  // FUNCTIONS
  const onCompressProjectsFn = useCallback(() => {
    setExpandedByIds((prev) => xor(prev, [project?.id]));
  }, [project?.id, setExpandedByIds]);

  const [sortedResources, setSortedProjects] = useState<TResourceItemList[]>(
    [],
  );

  useEffect(() => {
    setSortedProjects(project?.resources);
  }, [project?.resources]);

  const onDragStartFn = ({ active }: DragStartEvent) => {
    setActiveResourceSortingId(active?.id);
    setIsSorting(true);
  };

  const onDragEndFn = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = sortedResources.findIndex(
          (resource) => resource.id === active.id,
        );
        const newIndex = sortedResources.findIndex(
          (resource) => resource.id === over?.id,
        );
        const newItemsArray = arrayMove(sortedResources, oldIndex, newIndex);
        setSortedProjects(newItemsArray);
        // reorderMutation({
        //   resourceId: project?.id,
        //   newOrder: newItemsArray.map((project) => project.id),
        // });
      }
      setIsSorting(false);
      setActiveResourceSortingId(null);
    },
    [sortedResources, setIsSorting],
  );

  const renderWeekAllocation = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const fullTotalAllocation =
        project?.resources
          .flatMap(({ timeblocks }) => timeblocks ?? [])
          .filter((timeblock) => isWithinInterval(startDate, timeblock))
          .reduce((acc, { allocation }) => acc + allocation, 0) ?? 0;
      const txtContent = [];
      // if (fullTotalAllocation > 0)
      txtContent.push(
        t(
          `timeline:capacityInfo.allocated.${
            fullTotalAllocation === 1 ? mode?.slice(0, -1) : mode
          }`,
          { value: Number(fullTotalAllocation.toFixed(1)) },
        ),
      );
      const shade = fullTotalAllocation > 0 ? 7 : 1;
      return (
        <Tooltip.Provider
          key={virtualWeek.key}
          delayDuration={500}
          disableHoverableContent={fullTotalAllocation < 0}
        >
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                tabIndex={0}
                role="button"
                onKeyDown={(event) =>
                  event.key === 'Enter' && onCompressProjectsFn()
                }
                onClick={onCompressProjectsFn}
                style={
                  {
                    width: `${virtualWeek.size}px`,
                    transform: `translateX(${virtualWeek.start}px)`,
                  } as CSSProperties
                }
                key={virtualWeek.key}
                className={classNames(styles.totalAllocation, {
                  [styles.isExpanded]: layoutIsExpanded,
                  [styles.selected]:
                    hoverWeek && hoverWeek.getTime() === startDate.getTime(),
                })}
              >
                <span
                  className={classNames(
                    styles.dot,
                    styles.shaded,
                    styles[`shade--${shade}`],
                    styles.grey,
                  )}
                ></span>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal
              container={document.getElementById('radix-tooltip-portal')}
            >
              <Tooltip.Content className="TooltipContent" sideOffset={5}>
                {txtContent.join(', ')}
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    },
    [
      hoverWeek,
      layoutIsExpanded,
      mode,
      onCompressProjectsFn,
      project?.resources,
      weeks,
    ],
  );

  const isExpanded = expandedByIds?.includes(project?.id);
  const { emitter } = useEventEmitter('hoverWeek', setHoverWeek, isExpanded);

  useEffect(() => {
    return () => emitter?.unsubscribe('test', setHoverWeek);
  }, [emitter]);
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.header}>
          <ProjectHeader
            id={project?.id}
            isDraggableOverlay={isDraggableOverlay}
            name={project?.name}
            color={project?.color}
            type={project?.type}
            emoji={project?.emoji}
            status={project?.status}
            totalAllocation={project?.totalAllocation ?? 0}
            isExpanded={!isExpanded}
            onCompress={onCompressProjectsFn}
            sortHandlerProps={sortHandlerProps}
          />
        </div>
        {!isExpanded ? (
          <RecapBlock project={project} onClick={onCompressProjectsFn} />
        ) : (
          <div
            className={classNames(styles.allocation, {
              [styles.isExpanded]: layoutIsExpanded,
            })}
          >
            {virtualizer
              .getVirtualItems()
              .map((virtual) => renderWeekAllocation(virtual))}
          </div>
        )}
      </div>
      {isExpanded && (
        <>
          <div>
            <DndContext
              onDragStart={onDragStartFn}
              onDragEnd={onDragEndFn}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={sortedResources}
              >
                {sortedResources.map((resource) => (
                  <SortableResource
                    key={resource.id}
                    resource={resource}
                    activeSortingId={activeResourceSortingId}
                    project={project}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
          <div className={classNames([styles.row, styles.noHover])}>
            <div className={styles.header}>
              {project.type !== PROJECT_TYPE.TIME_OFF &&
              permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE ? (
                <AssignResourceButtonDropdown projectId={project?.id} />
              ) : undefined}
              <div style={{ height: 1, width: '100%' }}></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
