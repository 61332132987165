/* eslint-disable import/named */
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  DraggableAttributes,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as Tooltip from '@radix-ui/react-tooltip';
import type { VirtualItem } from '@tanstack/react-virtual';
import classNames from 'classnames';
import { isWithinInterval, setDefaultOptions } from 'date-fns';
import { t } from 'i18next';
import { find, max, min, xor } from 'lodash';
import { isMobile } from 'react-device-detect';

import { useEventEmitter } from '@/contexts/EventEmitterContext';
import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useReorderProjects from '@/hooks/workspace/resources/useReorderProjects';
import { linearMap } from '@/services/helpers';
import {
  PROJECT_TYPE,
  WORKSPACE_MEMBER_PERMISSION,
  WORKSPACE_MODE,
} from '@/types/enums';
import { TTimelineProject, TTimelineResource } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import AssignProjectButtonDropdown from '@/components/AssignProjectButtonDropdown';
import ResourceHeader from '@/components/Timelines/TimelineResources/ResourceHeader';
import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import RecapBlock from './RecapBlock';
import styles from './styles.module.css';
import HiddenRow from '../HiddenRow';
import ProjectRow from '../ProjectRow';
import SortableProject from '../SortableProject';

type Props = {
  resource: TTimelineResource;
  isDraggableOverlay?: boolean;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function AccordionRowWrapper({
  resource,
  sortHandlerProps,
  isDraggableOverlay,
}: Props) {
  setDefaultOptions({ weekStartsOn: 1 });
  const [hoverWeek, setHoverWeek] = useState<Date | undefined>(undefined);
  const {
    virtualizer,
    weeks,
    activeBlockIds,
    setActiveProjectId,
    setCompressedByIds,
    compressedByIds,
    setActiveResourceId,
    setIsSorting,
  } = useContext(TimelineResourcesContext);

  const { layoutIsExpanded } = useContext(UIContext);

  useEffect(() => {
    if (activeBlockIds.length === 0) {
      setActiveProjectId(null);
      setActiveResourceId(null);
    }
  }, [activeBlockIds.length, setActiveProjectId, setActiveResourceId]);

  const {
    account,
    workspaceId,
    workspace: { mode } = { mode: WORKSPACE_MODE.DAYS },
  } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;

  const [activeProjectSortingId, setActiveProjectSortingId] =
    useState<UniqueIdentifier | null>(null);
  const { mutate: reorderMutation } = useReorderProjects();
  // FUNCTIONS
  const onCompressProjectsFn = useCallback(() => {
    setCompressedByIds((prev) => xor(prev, [resource?.id]));
  }, [resource?.id, setCompressedByIds]);

  const [sortedProjects, setSortedProjects] = useState<TTimelineProject[]>([]);

  useEffect(() => {
    setSortedProjects(resource?.projects);
  }, [resource?.projects]);

  const onDragStartFn = ({ active }: DragStartEvent) => {
    setActiveProjectSortingId(active?.id);
    setIsSorting(true);
  };

  const onDragEndFn = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = sortedProjects.findIndex(
          (project) => project.id === active.id,
        );
        const newIndex = sortedProjects.findIndex(
          (project) => project.id === over?.id,
        );
        const newItemsArray = arrayMove(sortedProjects, oldIndex, newIndex);
        setSortedProjects(newItemsArray);
        reorderMutation({
          resourceId: resource?.id,
          newOrder: newItemsArray.map((project) => project.id),
        });
      }
      setIsSorting(false);
      setActiveProjectSortingId(null);
    },
    [sortedProjects, resource?.id, reorderMutation, setIsSorting],
  );

  // RENDER
  const renderWeekAllocation = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const resourceProjectsTimeblock = resource?.projects.flatMap(
        ({ timeblocks }) => timeblocks ?? [],
      );
      const fullTotalAllocation =
        (resourceProjectsTimeblock
          .filter((timeblock) => isWithinInterval(startDate, timeblock))
          .reduce((acc, { allocation }) => acc + allocation, 0) ?? 0) +
        (resource?.hidden
          ?.filter((ar) => isWithinInterval(startDate, ar))
          .flatMap(({ projects }) => projects)
          .reduce((acc, { allocation }) => acc + allocation, 0) ?? 0);

      const aboveCapacity = fullTotalAllocation - resource?.capacity;
      const available = resource?.capacity - fullTotalAllocation;
      let txtContent = [];

      const shade = Math.floor(
        linearMap(
          min([max([fullTotalAllocation, 1]), resource.capacity ?? 1]) ?? 1,
          1,
          resource.capacity,
          1,
          7,
        ),
      );
      if (resource.capacity > 0) {
        if (aboveCapacity > 0) {
          txtContent.push(
            t(
              `timeline:aboveCapacityWarning.${
                aboveCapacity === 1 ? mode?.slice(0, -1) : mode
              }`,
              { value: Number(aboveCapacity.toFixed(1)) },
            ),
          );
        } else {
          if (fullTotalAllocation > 0)
            txtContent.push(
              t(
                `timeline:capacityInfo.allocated.${
                  fullTotalAllocation === 1 ? mode?.slice(0, -1) : mode
                }`,
                { value: Number(fullTotalAllocation.toFixed(1)) },
              ),
            );
          if (available > 0) {
            txtContent.push(
              t(
                `timeline:capacityInfo.free.${
                  available === 1 ? mode?.slice(0, -1) : mode
                }`,
                {
                  value: Number(available.toFixed(1)),
                },
              ),
            );
          }
        }
      } else {
        txtContent = [
          t(
            `timeline:capacityInfo.free.${
              available === 1 ? mode?.slice(0, -1) : mode
            }`,
            {
              value: 0,
            },
          ),
        ];
        if (aboveCapacity > 0) {
          txtContent.push(
            t(
              `timeline:aboveCapacityWarning.${
                aboveCapacity === 1 ? mode?.slice(0, -1) : mode
              }`,
              { value: Number(aboveCapacity.toFixed(1)) },
            ),
          );
        }
      }

      return (
        <Tooltip.Provider key={virtualWeek.key} delayDuration={0}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                tabIndex={0}
                role="button"
                onKeyDown={(event) =>
                  event.key === 'Enter' && onCompressProjectsFn()
                }
                onClick={onCompressProjectsFn}
                style={
                  {
                    width: `${virtualWeek.size}px`,
                    transform: `translateX(${virtualWeek.start}px)`,
                  } as CSSProperties
                }
                className={classNames(styles.totalAllocation, {
                  [styles.isExpanded]: layoutIsExpanded,
                  [styles.selected]:
                    hoverWeek && hoverWeek.getTime() === startDate.getTime(),
                })}
              >
                <span
                  className={classNames(
                    styles.dot,
                    styles.shaded,
                    styles[`shade--${shade}`],
                    { [styles.grey]: aboveCapacity <= 0 },
                  )}
                ></span>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal
              container={document.getElementById('radix-tooltip-portal')}
            >
              <Tooltip.Content className="TooltipContent" sideOffset={5}>
                {txtContent.join(', ')}
                <Tooltip.Arrow className="TooltipArrow" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    },
    [
      hoverWeek,
      layoutIsExpanded,
      mode,
      onCompressProjectsFn,
      resource?.hidden,
      resource.capacity,
      resource?.projects,
      weeks,
    ],
  );

  const isCompressed = compressedByIds?.includes(resource?.id);
  const { emitter: _emitter } = useEventEmitter(
    'hoverWeek',
    setHoverWeek,
    !isCompressed,
  );

  const disabled = useMemo(
    () =>
      isMobile ||
      workspace?.permission !== WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE ||
      !resource?.capacity,
    [resource?.capacity, workspace?.permission],
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.header}>
            <ResourceHeader
              id={resource?.id}
              isDraggableOverlay={isDraggableOverlay}
              firstName={resource?.firstName}
              lastName={resource?.lastName}
              capacity={resource?.capacity}
              isCompressed={isCompressed ?? false}
              image={resource?.image}
              onCompress={onCompressProjectsFn}
              workspaceAccess={resource?.workspaceAccess}
              sortHandlerProps={sortHandlerProps}
            />
          </div>
          {isCompressed ? (
            <RecapBlock resource={resource} />
          ) : (
            <div
              className={classNames(styles.allocation, {
                [styles.isExpanded]: layoutIsExpanded,
              })}
            >
              {virtualizer
                .getVirtualItems()
                .map((virtual) => renderWeekAllocation(virtual))}
            </div>
          )}
        </div>
        {!isCompressed && (
          <>
            {Boolean(resource?.hiddenCount) && (
              <HiddenRow
                resourceId={resource.id}
                projects={resource.hidden}
                count={resource?.hiddenCount}
              >
                <HiddenRow.Header />
                <HiddenRow.Content>
                  <HiddenRow.Block />
                </HiddenRow.Content>
              </HiddenRow>
            )}
            {resource?.projects
              ?.filter((p) => p.type === PROJECT_TYPE.TIME_OFF)
              .map((project) => (
                <ProjectRow
                  key={project.id}
                  resource={resource}
                  project={project}
                  disabled={disabled}
                >
                  <ProjectRow.Header />
                  <ProjectRow.Content>
                    {activeBlockIds.length === 0 && !disabled && (
                      <ProjectRow.Cursor />
                    )}
                    <ProjectRow.Blocks />
                    <ProjectRow.NewBlock />
                    {!isMobile && <ProjectRow.ActivePanel />}
                  </ProjectRow.Content>
                </ProjectRow>
              ))}
            <div>
              <DndContext
                onDragStart={onDragStartFn}
                onDragEnd={onDragEndFn}
                autoScroll={{
                  threshold: { x: -110, y: 0.25 },
                }}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
              >
                <SortableContext
                  strategy={verticalListSortingStrategy}
                  items={sortedProjects}
                >
                  {sortedProjects
                    ?.filter((p) => p.type !== PROJECT_TYPE.TIME_OFF)
                    .map((project) => (
                      <SortableProject
                        key={project.id}
                        resource={resource}
                        activeSortingId={activeProjectSortingId}
                        project={project}
                        disabled={disabled}
                      />
                    ))}
                </SortableContext>
              </DndContext>
            </div>
            <div className={classNames([styles.row, styles.noHover])}>
              <div className={styles.header}>
                {workspace?.permission ===
                WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE ? (
                  <AssignProjectButtonDropdown id={resource.id} />
                ) : (
                  <div style={{ height: 1, width: '100%' }}></div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
