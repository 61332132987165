import * as Progress from '@radix-ui/react-progress';
import classNames from 'classnames';

import styles from './styles.module.css';

type ProgressBarProps = {
  max?: number;
  progress: number;
  overflow?: boolean;
};

export default function ProgressBar({
  max = 100,
  progress,
  overflow = false,
}: ProgressBarProps) {
  return (
    <Progress.Root
      className={styles.ProgressRoot}
      value={progress}
      max={max}
      getValueLabel={() => {
        return 'soppa';
      }}
    >
      <Progress.Indicator
        className={classNames(styles.ProgressIndicator, {
          [styles.overflow]: overflow,
        })}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  );
}
