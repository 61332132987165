import { useContext, useState } from 'react';

import { t } from 'i18next';

import { UserContext } from '@/contexts/UserContext';
import useCanDeleteAccount from '@/hooks/account/useCanDeleteAccount';

import Button from '@/components/Button';
import ModalCannotDeleteAccount from '@/components/Modals/ModalCannotDeleteAccount';
import ModalDeleteMyAccount from '@/components/Modals/ModalDeleteMyAccount';
import Section from '@/components/Section';

import styles from './styles.module.css';

export default function DeleteAccountSection() {
  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const { account } = useContext(UserContext);
  const { data: canDeleteAccount, isLoading } = useCanDeleteAccount({
    enabled: !!account,
  });
  return (
    <Section
      title={t('common:profileModal.workspaceSettings.dangerZone.title')}
    >
      <div className={styles.mainContainer}>
        <div className={styles.rowWrapper}>
          <div className={styles.rowLabelWrapper}>
            <div className={styles.rowLabel}>
              {t(`common:profileModal.myAccount.dangerZone.label`)}
            </div>
            <div className={styles.rowDescription}>
              {t(`common:profileModal.myAccount.dangerZone.description`)}
            </div>
          </div>
          <Button
            label={t(`common:profileModal.myAccount.dangerZone.action`)}
            variant="link"
            size="medium"
            onClick={() => setDeleteModalOpened(true)}
            className={styles.dangerButton}
          />
        </div>
      </div>
      {!isLoading &&
        deleteModalOpened &&
        (canDeleteAccount?.canDelete ? (
          <ModalDeleteMyAccount
            isOpen={true}
            onClose={() => setDeleteModalOpened(false)}
          />
        ) : (
          <ModalCannotDeleteAccount
            isOpen={true}
            onClose={() => setDeleteModalOpened(false)}
          />
        ))}
    </Section>
  );
}
