import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  IconChevronDown,
  IconChevronRight,
  IconDots,
  IconGripVertical,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import classNames from 'classnames';
import { t } from 'i18next';
import { find } from 'lodash';
import { RightSlot, contextMenu } from 'react-contexify-moze';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import { useAnalytics } from '@/hooks/utils/useAnalytics';
import { useUpdateProjectStatus } from '@/hooks/workspace/projects/useUpdateStatus';
import { getCssVariable, shrinkValue, truncate } from '@/services/helpers';
import { MENU_WIDTH } from '@/types/constants';
import {
  PROJECT_COLOR,
  PROJECT_STATUS,
  PROJECT_TYPE,
  WORKSPACE_MEMBER_PERMISSION,
} from '@/types/enums';
import { TProject } from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import Badge from '@/components/Badge';
import Button from '@/components/Button';
import ModalDeleteProject from '@/components/Modals/ModalDeleteProject';
import ModalEditProject from '@/components/Modals/ModalEditProject';
import ProjectEmoji from '@/components/ProjectEmoji';
import ShortcutMenu from '@/components/ShortcutMenu';

import styles from './styles.module.css';
import ChangeProjectStatusItem from '../../common/ChangeProjectStatusItem';
import RightSlotDelete from '../../common/utils/RightSlotDelete';

type Props = {
  id: string;
  isExpanded?: boolean;
  onCompress?: () => void;
  name: string;
  color: PROJECT_COLOR;
  emoji?: string;
  status?: PROJECT_STATUS;
  type: PROJECT_TYPE;
  totalAllocation: number;
  isDraggableOverlay?: boolean;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function ProjectHeader({
  id,
  isExpanded,
  onCompress,
  isDraggableOverlay,
  totalAllocation,
  type,
  name,
  color,
  emoji,
  status,
  sortHandlerProps,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>();
  const MENU_ID = useMemo(() => `team-context-menu-${id}`, [id]);

  const [isModalDeleteProjectOpen, setIsModalDeleteProjectOpen] =
    useState(false);
  const [isModalEditProjectOpen, setIsModalEditProjectOpen] = useState(false);
  const onOpenModalDeleteProject = useCallback(
    () => setIsModalDeleteProjectOpen(true),
    [],
  );
  const onCloseModalDeleteProject = useCallback(
    () => setIsModalDeleteProjectOpen(false),
    [],
  );

  const onOpenModalEdirProject = useCallback(
    () => setIsModalEditProjectOpen(true),
    [],
  );
  const onCloseModalEditProject = useCallback(
    () => setIsModalEditProjectOpen(false),
    [],
  );

  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (MENU_WIDTH - width), y: bottom + 8 };
    return menuPositionRef.current;
  }

  const isMdDevice = useMediaQuery('(min-width: 992px)');

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [menuIsOpen, MENU_ID],
  );

  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const { layoutIsExpanded } = useContext(UIContext);

  const [submenuOpened, setSubmenuOpened] = useState(false);

  const openSubMenu = useCallback(() => {
    setSubmenuOpened(true);
  }, []);

  const closeSubMenu = useCallback((clicked?: boolean) => {
    setSubmenuOpened(false);
    if (clicked) {
      contextMenu.hideAll();
    }
  }, []);

  const project = useMemo(() => {
    return {
      id,
      name,
      color,
      emoji,
      status,
      type,
    } as TProject;
  }, [color, emoji, id, name, status, type]);

  const { trackEvent } = useAnalytics();

  const { mutate: updateProjectStatus } = useUpdateProjectStatus();

  const onItemClickFn = useCallback(
    (newStatus: PROJECT_STATUS) => {
      updateProjectStatus(
        { projectId: project.id, status: newStatus },
        {
          onSuccess: () => {
            trackEvent('PROJECT_STATUS_CHANGED', workspaceId as string);
          },
        },
      );
      closeSubMenu(true);
    },
    [closeSubMenu, project.id, trackEvent, updateProjectStatus, workspaceId],
  );

  return (
    <>
      <div
        aria-hidden
        className={classNames(styles.container, {
          [styles.isExpanded]: layoutIsExpanded,
        })}
        onClick={onCompress}
      >
        <div className={styles.content}>
          {sortHandlerProps?.activator && (
            <div
              className={classNames(styles.sortHandler, {
                [styles.sortHandlerHidden]:
                  type === PROJECT_TYPE.TIME_OFF ||
                  workspace?.permission ===
                    WORKSPACE_MEMBER_PERMISSION.READ_ONLY,
              })}
              {...sortHandlerProps?.listeners}
              {...sortHandlerProps?.attributes}
              ref={sortHandlerProps?.activator}
            >
              <IconGripVertical
                size={16}
                color={getCssVariable('--color-neutral-500')}
              />
            </div>
          )}

          {isDraggableOverlay && (
            <div className={styles.sortHandler}>
              <IconGripVertical
                size={16}
                color={getCssVariable('--color-neutral-500')}
              />
            </div>
          )}
          <span className={styles.collapse}>
            {!isExpanded ? (
              <IconChevronDown size={16} />
            ) : (
              <IconChevronRight size={16} />
            )}
          </span>
          <div className={styles.avatar}>
            {isMdDevice ? (
              <ProjectEmoji emoji={emoji} color={color} />
            ) : (
              <Badge
                type={type === PROJECT_TYPE.TIME_OFF ? 'timeoff' : 'white'}
              >
                {truncate(name, 12)}
              </Badge>
            )}
          </div>
          <div className={styles.desktop}>
            <p className={styles.resourceName}>{name}</p>
            <span className={styles.totalAllocation}>
              {shrinkValue(totalAllocation)}
            </span>
            {workspace?.permission ===
              WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE &&
              type !== PROJECT_TYPE.TIME_OFF && (
                <React.Fragment>
                  <Button
                    ref={triggerRef}
                    icon={IconDots}
                    variant="ghost"
                    size="small"
                    onClick={onClickFn}
                    className={styles.noFocus}
                  />
                  <ShortcutMenu
                    onVisibilityChange={(isVisible: boolean) => {
                      setMenuIsOpen(isVisible);
                      setSubmenuOpened(false);
                    }}
                    style={{ minWidth: MENU_WIDTH }}
                    animation={false}
                    id={MENU_ID}
                  >
                    <ShortcutMenu.Item
                      keyHandler={onOpenModalEdirProject}
                      keyMatch="e"
                    >
                      <IconPencil size={20} style={{ marginRight: 8 }} />{' '}
                      {t('common:editContextMenuActions.edit')}{' '}
                      <RightSlot>E</RightSlot>{' '}
                    </ShortcutMenu.Item>
                    <ChangeProjectStatusItem
                      menuId={MENU_ID}
                      project={project}
                      keyMatch={'s'}
                      keyHandler={() => setSubmenuOpened((prev) => !prev)}
                      onMouseEnter={openSubMenu}
                      onMouseLeave={() => closeSubMenu()}
                      onFocus={openSubMenu}
                      submenuOpened={submenuOpened}
                      menuWidth={MENU_WIDTH}
                      onItemClick={onItemClickFn}
                      view="projects"
                    />
                    <ShortcutMenu.Item
                      keyHandler={onOpenModalDeleteProject}
                      keyMatch="shift+backspace"
                    >
                      <IconTrash size={20} style={{ marginRight: 8 }} />{' '}
                      {t('common:editContextMenuActions.delete')}{' '}
                      <RightSlotDelete />
                    </ShortcutMenu.Item>
                  </ShortcutMenu>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
      {id && isModalEditProjectOpen && (
        <ModalEditProject
          name={name ?? ''}
          isOpen={isModalEditProjectOpen}
          color={color ?? PROJECT_COLOR.GREY}
          projectEmoji={emoji}
          projectStatus={status}
          projectId={id}
          onClose={onCloseModalEditProject}
        />
      )}
      {isModalDeleteProjectOpen && (
        <ModalDeleteProject
          name={name}
          projectId={id}
          isOpen={isModalDeleteProjectOpen}
          onClose={onCloseModalDeleteProject}
        />
      )}
    </>
  );
}
