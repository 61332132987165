import { Fragment, useContext } from 'react';

import { format } from 'date-fns';
import { t } from 'i18next';

import { UIContext } from '@/contexts/UIContext';
import useInsightsPartQuery from '@/hooks/insights/useInsightsPartQuery';
import { INSIGHTS_TYPE, PROJECT_TYPE } from '@/types/enums';
import { TInsightsStartingSoon } from '@/types/insights';

import styles from './styles.module.css';
import DrawerCard from '../DrawerCard';
import ProjectNameWithEmoji from '../ProjectNameWithEmoji';
import ShowMore from '../ShowMore';
import ListSkeleton from '../skeleton/ListSkeleton';

export default function StartingSoonDrawer() {
  const { drawerIsOpen } = useContext(UIContext);

  const { data: startingSoon, isLoading } = useInsightsPartQuery({
    insightsType: INSIGHTS_TYPE.STARTING_SOON,
    min: 0,
    max: 4,
    enabled: drawerIsOpen,
  });

  const startingSoonDifference =
    (startingSoon?.count &&
      startingSoon?.count - (startingSoon?.data?.length ?? 0)) ??
    0;
  function renderStartingSoon(item: TInsightsStartingSoon, key: number) {
    return (
      <li className={styles.item} key={`starting-soon-${key}`}>
        <div className={styles.subItem}>
          <ProjectNameWithEmoji
            type={PROJECT_TYPE.PROJECT}
            name={item?.name}
            emoji={item?.emoji}
            color={item?.color}
          />
        </div>
        <div className={styles.horizontalList}>
          <p>{format(item?.begin, 'd MMM')}</p>
        </div>
      </li>
    );
  }

  const renderStartingSoonList = (data: TInsightsStartingSoon[]) => {
    return <ul className={styles.list}>{data?.map(renderStartingSoon)}</ul>;
  };
  const title = t('common:insights.startingSoon');
  const rightTitle = t('common:insights.nextTreeWeeks');

  return (
    <DrawerCard
      draweId="startingSoon"
      emoji="⏳"
      title={t('common:insights.startingSoon')}
      isLoading={isLoading}
      loader={<ListSkeleton />}
      rightTitle={t('common:insights.nextTreeWeeks')}
      emptyLabel={t('common:insights.noStartingSoonProjectsLabel')}
    >
      {startingSoon?.count ? (
        <>
          {renderStartingSoonList(startingSoon?.data ?? [])}
          {startingSoonDifference ? (
            <ShowMore
              insightsType={INSIGHTS_TYPE.STARTING_SOON}
              renderData={renderStartingSoonList}
              triggerLabel={`+ ${startingSoonDifference} ${t('common:more')}`}
              open={false}
              type="modal"
              titleClassName={styles.modalHeader}
              title={
                <Fragment>
                  <h2>⏳ {title}</h2>
                  <p>{rightTitle}</p>
                </Fragment>
              }
            />
          ) : (
            false
          )}
        </>
      ) : (
        false
      )}
    </DrawerCard>
  );
}
