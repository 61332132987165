import { Fragment, useContext, useMemo } from 'react';

import { t } from 'i18next';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useInsightsPartQuery from '@/hooks/insights/useInsightsPartQuery';
import { shrinkValue } from '@/services/helpers';
import { INSIGHTS_TYPE, PROJECT_TYPE, WORKSPACE_MODE } from '@/types/enums';
import { TInsightsStartingSoon, TInsightsTopProjects } from '@/types/insights';

import styles from './styles.module.css';
import DrawerCard from '../DrawerCard';
import ProjectNameWithEmoji from '../ProjectNameWithEmoji';
import ShowMore from '../ShowMore';
import ListSkeleton from '../skeleton/ListSkeleton';

export default function TopProjectDrawer() {
  const { drawerIsOpen } = useContext(UIContext);

  const { data: topProjects, isLoading } = useInsightsPartQuery({
    insightsType: INSIGHTS_TYPE.TOP_PROJECTS,
    min: 0,
    max: 4,
    enabled: drawerIsOpen,
  });
  const { workspaceMode } = useContext(UserContext);

  const topProjectsDifference =
    (topProjects?.count &&
      topProjects?.count - (topProjects?.data?.length ?? 0)) ??
    0;

  function renderTopProjectsItemItem(
    item: TInsightsStartingSoon | TInsightsTopProjects,
    key: number,
  ) {
    const isOne = WORKSPACE_MODE.DAYS ? item?.effort === 1 : item?.effort === 8;
    const effort = shrinkValue(item?.effort);
    return (
      <li className={styles.item} key={`starting-soon-${key}`}>
        <div className={styles.subItem}>
          <ProjectNameWithEmoji
            type={PROJECT_TYPE.PROJECT}
            name={item?.name}
            emoji={item?.emoji}
            color={item?.color}
          />
        </div>
        <div className={styles.horizontalList}>
          <p>{`${effort} ${
            isOne
              ? t(
                  `common:${
                    workspaceMode === WORKSPACE_MODE.DAYS ? 'day' : 'hour'
                  }`,
                )
              : t(`common:${workspaceMode?.toLowerCase()}`)
          }`}</p>
        </div>
      </li>
    );
  }

  const title = useMemo(() => t('common:insights.topProjects'), []);
  const rightTitle = useMemo(() => t('common:insights.last12months'), []);

  return (
    <DrawerCard
      draweId="topProjects"
      emoji="🥇"
      title={title}
      rightTitle={rightTitle}
      isLoading={isLoading}
      loader={<ListSkeleton />}
      emptyLabel={t('common:insights.noTopProjectsLabel')}
    >
      {topProjects?.count ? (
        <>
          <ul className={styles.list}>
            {topProjects?.data?.map(renderTopProjectsItemItem)}
          </ul>
          {topProjectsDifference ? (
            <ShowMore
              insightsType={INSIGHTS_TYPE.TOP_PROJECTS}
              renderData={(data) => {
                return (
                  <ul className={styles.list}>
                    {data?.map(renderTopProjectsItemItem)}
                  </ul>
                );
              }}
              triggerLabel={`+ ${topProjectsDifference} ${t('common:more')}`}
              open={false}
              type="modal"
              titleClassName={styles.modalHeader}
              title={
                <Fragment>
                  <h2>🥇 {title}</h2>
                  <p>{rightTitle}</p>
                </Fragment>
              }
            />
          ) : (
            false
          )}
        </>
      ) : (
        false
      )}
    </DrawerCard>
  );
}
