import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { UIContext } from '@contexts/UIContext';
import { UserContext } from '@contexts/UserContext';
import type { Virtualizer } from '@tanstack/react-virtual';
import { useVirtualizer } from '@tanstack/react-virtual';
import type { AxiosError } from 'axios';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import {
  add,
  eachWeekOfInterval,
  endOfDay,
  format,
  setDefaultOptions,
  startOfDay,
  startOfToday,
  startOfWeek,
  sub,
} from 'date-fns';
import { filter, find, forEach, isUndefined, map, noop, omit } from 'lodash';

import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import useAllocation from '@/hooks/workspace/resources/useAllocation';
import useTimelineResourceQuery from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { getCssVariable } from '@/services/helpers';
import { PlannException } from '@/types/base-responses';
import { ALLOCATION_EVENT_OPERATION_TYPE } from '@/types/enums';
import {
  TAllocationEvent,
  TTimeBlockRange,
  TTimelineProject,
  TTimelineResource,
} from '@/types/timeline';

import ModalUpgradePlan from '@/components/Modals/ModalUpgradePlan';

export const WEEK_ROW_HIGH_SIZE_COMPRESSED = 56;
export const WEEK_ROW_HIGH_SIZE_EXPANDED = 80;
export const WEEKS_TO_ADD = 20;
export const WORKING_DAYS_IN_A_WEEK = 5;

const TimelineResourcesContext = React.createContext<{
  currentScrollSize: number;
  leftPadding: number;
  onNextFn: () => void;
  onPrevFn: () => void;
  isSorting: boolean;
  setIsSorting: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentScrollSize: React.Dispatch<React.SetStateAction<number>>;
  setResetViewOnToday: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveBlockIds: React.Dispatch<React.SetStateAction<string[]>>;
  setCompressedByIds: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveProjectId: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveResourceId: React.Dispatch<React.SetStateAction<string | null>>;
  resetViewOnToday: boolean;
  compressedByIds: string[] | null;
  onDeleteBlockFn: (blockIds: string[]) => void;
  isLoading: boolean;
  updateBlocksAllocation: (allocation: string) => void;
  activeBlockIds: string[];
  isLoadingMutation: boolean;
  weekSizeInPx: number;
  activeProjectId: string | null;
  activeResourceId: string | null;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  ref: React.RefObject<HTMLDivElement>;
  sidebarRef: React.RefObject<HTMLDivElement>;
  updateTimeInterval: (when: 'before' | 'after') => void;
  timeInterval: { start: Date; end: Date };
  weeks: Date[];
  data: TTimelineResource[];
  selectedBlockId: string | null;
  setSelectedBlockId: React.Dispatch<React.SetStateAction<string | null>>;
  blocksToUpdate: {
    events: TTimeBlockRange[];
    projectId: string;
    resourceId: string;
    shouldInvalidated?: boolean;
  };
  setBlocksToUpdate: React.Dispatch<
    React.SetStateAction<{
      events: TTimeBlockRange[];
      projectId: string;
      resourceId: string;
      shouldInvalidated?: boolean;
    }>
  >;

  setData: React.Dispatch<React.SetStateAction<TTimelineResource[]>>;
  updateData: (newBlock: TTimeBlockRange) => void;
}>({
  currentScrollSize: 0,
  isLoading: false,
  isSorting: false,
  setIsSorting: noop,
  updateData: noop,
  onNextFn: noop,
  onPrevFn: noop,
  leftPadding: 0,
  isLoadingMutation: false,
  setCompressedByIds: noop,
  activeBlockIds: [],
  activeProjectId: null,
  activeResourceId: null,
  compressedByIds: [],
  selectedBlockId: null,
  setSelectedBlockId: noop,
  weekSizeInPx: WEEK_ROW_HIGH_SIZE_COMPRESSED,
  updateBlocksAllocation: noop,
  onDeleteBlockFn: noop,
  setCurrentScrollSize: noop,
  virtualizer: {} as Virtualizer<HTMLDivElement, Element>,
  resetViewOnToday: true,
  ref: {} as React.RefObject<HTMLDivElement>,
  sidebarRef: {} as React.RefObject<HTMLDivElement>,
  setResetViewOnToday: noop,
  setBlocksToUpdate: noop,
  setActiveBlockIds: noop,
  setActiveProjectId: noop,
  setActiveResourceId: noop,
  updateTimeInterval: noop,
  blocksToUpdate: {
    events: [],
    projectId: '',
    resourceId: '',
    shouldInvalidated: false,
  },
  timeInterval: { start: new Date(), end: new Date() },
  weeks: [],
  data: [],
  setData: noop,
});

const TimelineProvider = ({ children }: PropsWithChildren) => {
  setDefaultOptions({ weekStartsOn: 1 });
  const [isSorting, setIsSorting] = useState(false);

  const { layoutIsExpanded } = useContext(UIContext);

  const [compressedByIds, setCompressedByIds] = useLocalStorage<string[]>(
    `collapsed-resources`,
    [],
  );

  const isMdDevice = useMediaQuery('(min-width: 992px)');
  const [modalOpen, setModalOpen] = useState(false);

  const [blocksToUpdate, setBlocksToUpdate] = useState<{
    events: TTimeBlockRange[];
    projectId: string;
    resourceId: string;
    shouldInvalidated?: boolean;
  }>({
    events: [],
    projectId: '',
    resourceId: '',
    shouldInvalidated: false,
  });

  const { trackEvent } = useAnalytics();
  const { workspaceId } = useContext(UserContext);
  const [currentScrollSize, setCurrentScrollSize] = useState(0);
  const [resetViewOnToday, setResetViewOnToday] = useState(true);
  const [activeBlockIds, setActiveBlockIds] = useState<string[]>([]);
  const [activeProjectId, setActiveProjectId] = useState<string | null>(null);
  const [activeResourceId, setActiveResourceId] = useState<string | null>(null);

  const leftPadding = isMdDevice
    ? Number(getCssVariable('--sidebar-width')?.replace('px', ''))
    : 0;

  const today = startOfToday();
  const currentWeek = startOfWeek(today);
  const initialTimeInterval = {
    start: sub(currentWeek, { weeks: 40 }),
    end: add(currentWeek, { weeks: 40 }),
  };

  const [timeInterval, setTimeInterval] = useState(initialTimeInterval);

  const {
    data: timelineResources = [],
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
  } = useTimelineResourceQuery({
    onSuccess: noop,
    onError: noop,
    timeInterval: initialTimeInterval,
  });

  const [data, setData] = useState<TTimelineResource[]>(timelineResources);

  useEffect(() => {
    setData(timelineResources);
  }, [timelineResources]);

  const weekSizeInPx = layoutIsExpanded
    ? WEEK_ROW_HIGH_SIZE_EXPANDED
    : WEEK_ROW_HIGH_SIZE_COMPRESSED;

  const updateTimeInterval = (when: 'before' | 'after') => {
    let newTimeInterval;
    if (when === 'before') {
      newTimeInterval = {
        start: sub(timeInterval.start, { weeks: WEEKS_TO_ADD }),
        end: timeInterval.end,
      };
      fetchPreviousPage({
        pageParam: {
          start: sub(timeInterval.start, { weeks: WEEKS_TO_ADD }),
          end: endOfDay(sub(timeInterval.start, { days: 1 })),
        },
      });
    } else {
      newTimeInterval = {
        start: timeInterval.start,
        end: add(timeInterval.end, { weeks: WEEKS_TO_ADD }),
      };
      fetchNextPage({
        pageParam: {
          start: startOfDay(add(timeInterval.end, { days: 1 })),
          end: add(timeInterval.end, { weeks: WEEKS_TO_ADD }),
        },
      });
    }
    setTimeInterval(newTimeInterval);
  };

  const weeks = useMemo(() => eachWeekOfInterval(timeInterval), [timeInterval]);
  const ref = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null);

  const virtualizer = useVirtualizer({
    count: weeks.length,
    horizontal: true,
    overscan: 10,
    getScrollElement: () => ref.current,
    estimateSize: () => weekSizeInPx,
  });

  const onNextFn = useCallback(() => {
    virtualizer.scrollBy(weekSizeInPx * 8);
  }, [virtualizer, weekSizeInPx]);

  const onPrevFn = useCallback(() => {
    virtualizer.scrollBy(-weekSizeInPx * 8);
  }, [virtualizer, weekSizeInPx]);

  useEffect(() => {
    virtualizer.measure();
    setCurrentScrollSize((prevValue) => {
      if (prevValue !== 0) {
        const newValue = layoutIsExpanded
          ? (WEEK_ROW_HIGH_SIZE_EXPANDED * prevValue) /
            WEEK_ROW_HIGH_SIZE_COMPRESSED
          : (WEEK_ROW_HIGH_SIZE_COMPRESSED * prevValue) /
            WEEK_ROW_HIGH_SIZE_EXPANDED;
        virtualizer.scrollToOffset(newValue);
        return newValue;
      } else {
        return prevValue;
      }
    });
  }, [layoutIsExpanded, virtualizer]);

  useEffect(() => {
    setCurrentScrollSize((prevValue) =>
      virtualizer.scrollOffset !== prevValue
        ? virtualizer.scrollOffset
        : prevValue,
    );
  }, [setCurrentScrollSize, virtualizer.scrollOffset]);

  const onSubscriptionOverflow = useCallback((error: Error) => {
    if (error.name === 'AxiosError') {
      const { response } = error as AxiosError<PlannException>;
      if (response?.data.code === 'SUBSCRIPTION/SUBSCRIPTION_OVERFLOW') {
        setModalOpen(true);
        setActiveProjectId(null);
        setActiveResourceId(null);
        setActiveBlockIds([]);
      }
    }
  }, []);

  const { mutate: mutateAllocation, isLoading: isLoadingMutation } =
    useAllocation({
      onError: onSubscriptionOverflow,
    });

  useEffect(() => {
    const blocks = blocksToUpdate?.events.map((b) => ({
      ...omit(b, ['start', 'end', 'id']),
      allocationId: b.id,
      startDate: String(format(b.start, 'yyyy-MM-dd')),
      endDate: String(format(b.end, 'yyyy-MM-dd')),
    })) as TAllocationEvent[];
    if (!blocks.length) return;

    mutateAllocation(
      {
        events: blocks,
        projectId: blocksToUpdate.projectId,
        resourceId: blocksToUpdate.resourceId,
        shouldInvalidate: blocksToUpdate.shouldInvalidated,
      },
      {
        onSuccess: () => {
          forEach(blocks, (block) => {
            let event;
            switch (block?.operation) {
              case ALLOCATION_EVENT_OPERATION_TYPE.INSERT:
                event = ANALYTICS_EVENTS.BLOCK_CREATED;
                break;
              case ALLOCATION_EVENT_OPERATION_TYPE.UPDATE:
                event = ANALYTICS_EVENTS.BLOCK_UPDATED;
                break;
              case ALLOCATION_EVENT_OPERATION_TYPE.DELETE:
                event = ANALYTICS_EVENTS.BLOCK_DELETED;
                break;
              default:
                event = null;
            }
            if (!event) return;
            trackEvent(event, workspaceId as string);
          });
          setBlocksToUpdate({
            events: [],
            projectId: '',
            resourceId: '',
            shouldInvalidated: false,
          });
        },
      },
    );
  }, [blocksToUpdate, trackEvent, mutateAllocation, workspaceId]);

  const timelineResource = useMemo(
    () => find(data, { id: activeResourceId }) as TTimelineResource,
    [data, activeResourceId],
  );

  const currentProject = useMemo(
    () =>
      find(timelineResource?.projects, {
        id: activeProjectId,
      }) as TTimelineProject,
    [timelineResource, activeProjectId],
  );

  const onDeleteBlockFn = useCallback(
    (blockIds: string[]) => {
      const allBlocksToBeDeleted = blockIds.map((allocationId) => ({
        allocationId,
        operation: ALLOCATION_EVENT_OPERATION_TYPE.DELETE,
      }));
      mutateAllocation(
        {
          events: allBlocksToBeDeleted as unknown as TAllocationEvent[],
          projectId: activeProjectId as string,
          resourceId: activeResourceId as string,
        },
        {
          onSuccess: () => {
            setActiveBlockIds([]);
            trackEvent(ANALYTICS_EVENTS.BLOCK_DELETED, workspaceId as string);
          },
        },
      );
      const newTimeblocks = filter(currentProject?.timeblocks, (tb) => {
        return !blockIds.includes(tb.id);
      });
      // Update projects
      const newProjects = map(timelineResource?.projects, (p) => {
        return p.id === activeProjectId
          ? { ...p, timeblocks: newTimeblocks }
          : p;
      });
      // Update teosurce
      const newResource = { ...timelineResource, projects: newProjects };
      // Update context
      if (JSON.stringify(newResource) === JSON.stringify(timelineResource))
        return;
      setData((prev) =>
        prev?.map((p) => (p.id !== timelineResource.id ? p : newResource)),
      );
    },
    [
      mutateAllocation,
      activeProjectId,
      activeResourceId,
      currentProject?.timeblocks,
      timelineResource,
      trackEvent,
      workspaceId,
    ],
  );

  const updateData = useCallback(
    (newBlock: TTimeBlockRange) => {
      // Update blocks
      const newTimeblocks = map(currentProject?.timeblocks, (tb) => {
        return tb.id === newBlock.id ? newBlock : tb;
      });
      // Update projects
      const newProjects = map(timelineResource?.projects, (p) => {
        return p.id === activeProjectId
          ? { ...p, timeblocks: newTimeblocks }
          : p;
      });
      // Update resource
      const newResource = { ...timelineResource, projects: newProjects };
      // Update context
      if (JSON.stringify(newResource) === JSON.stringify(timelineResource))
        return;
      if (isUndefined(timelineResource) || isUndefined(newResource)) return;
      setData((prev) =>
        prev?.map((p) => (p.id !== timelineResource.id ? p : newResource)),
      );
    },
    [timelineResource, currentProject?.timeblocks, activeProjectId, setData],
  );

  const updateBlocksAllocation = useCallback(
    (allocation: string) => {
      const allBlocks = filter(currentProject?.timeblocks, (block) => {
        return activeBlockIds.includes(block?.id);
      });

      if (allBlocks.length > 0) {
        const allBlocksToBeUpdated = allBlocks.map((b) => {
          updateData({
            ...b,
            allocation: Number(allocation),
          });
          return {
            allocationId: b.id,
            allocation: Number(allocation),
            startDate: String(format(b.start, 'yyyy-MM-dd')),
            endDate: String(format(b.end, 'yyyy-MM-dd')),
            operation: ALLOCATION_EVENT_OPERATION_TYPE.UPDATE,
          };
        }) as TAllocationEvent[];

        mutateAllocation(
          {
            events: allBlocksToBeUpdated,
            projectId: activeProjectId as string,
            resourceId: activeResourceId as string,
          },
          {
            onSuccess: () => {
              trackEvent(ANALYTICS_EVENTS.BLOCK_UPDATED, workspaceId as string);
            },
          },
        );
      }
    },
    [
      currentProject?.timeblocks,
      mutateAllocation,
      activeProjectId,
      activeResourceId,
      activeBlockIds,
      workspaceId,
      updateData,
      trackEvent,
    ],
  );

  return (
    <TimelineResourcesContext.Provider
      value={{
        data,
        setIsSorting,
        isSorting,
        setData,
        setBlocksToUpdate,
        blocksToUpdate,
        currentScrollSize,
        setCurrentScrollSize,
        virtualizer,
        setActiveResourceId,
        updateData,
        activeResourceId,
        selectedBlockId,
        setSelectedBlockId,
        setActiveBlockIds,
        updateBlocksAllocation,
        activeBlockIds,
        weekSizeInPx,
        isLoadingMutation,
        compressedByIds: compressedByIds,
        setCompressedByIds: setCompressedByIds,
        ref,
        sidebarRef,
        resetViewOnToday,
        leftPadding,
        isLoading,
        setResetViewOnToday,
        updateTimeInterval,
        timeInterval,
        weeks,
        activeProjectId,
        setActiveProjectId,
        onDeleteBlockFn,
        onNextFn,
        onPrevFn,
      }}
    >
      {children}
      {modalOpen && (
        <ModalUpgradePlan
          modalOpened={modalOpen}
          onClose={() => setModalOpen(false)}
          changePlanTo="pro"
        />
      )}
    </TimelineResourcesContext.Provider>
  );
};

export { TimelineProvider, TimelineResourcesContext };
