import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { http } from '@/services/api';

export default function useDeleteWorkspace() {
  const queryClient = useQueryClient();
  const { clearLocalStorage } = useContext(UserContext);

  return useMutation(
    async (workspaceId: string) => {
      await http.delete(`/workspaces/${workspaceId}`);
    },
    {
      onSuccess: () => {
        queryClient.clear();
        clearLocalStorage(false);
      },
    },
  );
}
