import Input from '@components/Input';
import { validate as EmailValidator } from 'email-validator';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';

import styles from '../SignInForm/styles.module.css';

export type FormData = { email: string };

type Props = {
  /**
   * Function to call when the form is submitted
   * @param data
   */
  onSubmitFn: (data: FormData) => void;
  /**
   * Loading state of the button
   */
  isLoading?: boolean;
};

export default function RecoverPasswordForm({ onSubmitFn, isLoading }: Props) {
  const { t } = useTranslation(['common', 'forms']);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onSubmit' });

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmitFn)} noValidate>
        <div className={styles.field} style={{ marginBottom: 24 }}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:signIn.email.required'),
              },
              validate: (value) => {
                if (!EmailValidator(value)) {
                  return t('forms:signIn.email.invalid') as string;
                }
                return true;
              },
            }}
            control={control}
            name="email"
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  name={name}
                  type="text"
                  size="large"
                  value={value}
                  label={t('forms:signIn.email.label') as string}
                  placeholder={t('forms:signIn.email.placeholder')}
                  onChange={onChange}
                  error={errors?.email?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.button}>
          <Button
            label={t('forms:recoverPassword.submit') ?? ''}
            isLoading={isLoading}
            size="large"
          />
        </div>
      </form>
    </div>
  );
}
