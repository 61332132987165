import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


const locRegex: RegExp = /^.*loc=([A-Z]{2}).*/gs;
const COUNTRY_CODE_GET = 'get-country-code';
export default function useGetCountryCode() {
  return useQuery([COUNTRY_CODE_GET], async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_SITE_URL}/cdn-cgi/trace`,
      );
      const [_input, countryCode] = locRegex.exec(
        response.data,
      ) as Array<string>;
      return countryCode ?? 'IT';
    } catch (error) {
      return 'IT';
    }
  });
}
