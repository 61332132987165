import { useContext, useMemo } from 'react';

import classNames from 'classnames';
import { t } from 'i18next';

import { UserContext } from '@/contexts/UserContext';
import { titleCase } from '@/services/helpers';
import { USER_ROLE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';

import Avatar from '@/components/Avatar';
import Chip from '@/components/Chip';
import SelectDropdown from '@/components/SelectDropdown';

import styles from './styles.module.css';

type Props = {
  workspaceId: string;
  resources: TResourceSearchResult[];
  onUpdatePermission?: (data: {
    resource: TResourceSearchResult;
    permission: WORKSPACE_MEMBER_PERMISSION;
    workspaceId: string;
  }) => void;
  onUpdateRole: (data: {
    workspaceId: string;
    role: USER_ROLE;
    accountId: string;
  }) => void;
};

export default function MemberManageSection({
  workspaceId,
  resources,
  onUpdatePermission,
  onUpdateRole,
}: Props) {
  const { account } = useContext(UserContext);
  const hasPermissionUpdate = useMemo(
    () => !!onUpdatePermission,
    [onUpdatePermission],
  );
  const PERMISSION_DATA: Record<WORKSPACE_MEMBER_PERMISSION, string> = useMemo(
    () => ({
      [WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE]: titleCase(
        t('common:modals.roles.editor.label'),
      ) as string,
      [WORKSPACE_MEMBER_PERMISSION.READ_ONLY]: titleCase(
        t('common:modals.roles.viewer.label'),
      ) as string,
    }),
    [],
  );
  return (
    <div
      className={classNames(styles.peopleContainer, {
        [styles.withPermission]: hasPermissionUpdate,
      })}
    >
      <div className={styles.tableHeaders}>
        <span>User</span>
        <span>Role</span>
        {hasPermissionUpdate && <span>Permission</span>}
      </div>

      {resources?.length ? (
        resources.map((resource) => {
          return (
            <div key={resource.id} className={styles['grid-row']}>
              <div className={classNames(styles.userInfo, styles['grid-cell'])}>
                <Avatar
                  name={`${resource.firstName} ${resource.lastName}`}
                  image={resource.image?.ref}
                  size="large"
                />
                <div className={styles.userInfoText}>
                  <span className={styles.userInfoTextName}>
                    <span>{`${resource.firstName} ${resource.lastName}`}</span>
                    {account && account.email === resource.email ? (
                      <Chip size="small" type="neutral">
                        you
                      </Chip>
                    ) : resource.invitation ? (
                      <Chip size="small" type="informative">
                        {t('common:invited')}
                      </Chip>
                    ) : undefined}
                  </span>
                  {resource.email && (
                    <span
                      className={styles.userInfoTextEmail}
                    >{`${resource.email}`}</span>
                  )}
                </div>
              </div>

              <span className={styles['grid-cell']}>
                <SelectDropdown
                  variant="simple"
                  align="end"
                  label={titleCase(resource?.role ?? 'MEMBER') as string}
                  disabled={
                    !!resource.invitation || account?.email === resource.email
                  }
                >
                  {Object.values(USER_ROLE).map((ur) => (
                    <SelectDropdown.Item
                      checked={resource.role === ur}
                      key={ur}
                      label={titleCase(ur) as string}
                      disabled={!resource.accountId || resource.role === ur}
                      onSelect={() => {
                        if (workspaceId && resource.accountId)
                          onUpdateRole({
                            workspaceId,
                            role: ur,
                            accountId: resource.accountId,
                          });
                      }}
                    />
                  ))}
                </SelectDropdown>
              </span>
              {hasPermissionUpdate && (
                <span className={styles['grid-cell']}>
                  <SelectDropdown
                    variant="simple"
                    align="end"
                    disabled={resource.role === USER_ROLE.OWNER}
                    label={
                      resource.permission
                        ? PERMISSION_DATA[resource.permission]
                        : ''
                    }
                  >
                    {Object.values(WORKSPACE_MEMBER_PERMISSION).map((p) => (
                      <SelectDropdown.Item
                        checked={resource.permission === p}
                        key={p}
                        label={PERMISSION_DATA[p]}
                        disabled={resource.permission === p}
                        onSelect={() =>
                          onUpdatePermission?.({
                            resource,
                            permission: p,
                            workspaceId,
                          })
                        }
                      />
                    ))}
                  </SelectDropdown>
                </span>
              )}
            </div>
          );
        })
      ) : (
        <div className={styles.noData}>
          <div className={styles.noData_text_container}>
            <span>No resources found</span>
          </div>
        </div>
      )}
    </div>
  );
}
