import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { http } from '@/services/api';
import { normalizeTransaction } from '@/services/normalizer';
import { BaseResponse } from '@/types/base-responses';
import { Dto } from '@/types/generic';
import { TTransactions } from '@/types/invoices';

type Props = {
  subscriptionId: string;
  enabled?: boolean;
};
export const GET_PAYMENT_UPDATE_TRANSACTION_KEY =
  'get-payment-update-transaction';
export default function useGetPaymentUpdateTransaction({
  subscriptionId,
  enabled = true,
}: Props) {
  const { workspace } = useContext(UserContext);
  const KEY = [
    GET_PAYMENT_UPDATE_TRANSACTION_KEY,
    workspace?.id,
    subscriptionId,
  ];
  return useQuery(
    KEY,
    async ({ queryKey: [_k, wsId, subId] }) => {
      const {
        data: { data },
      } = await http.get<BaseResponse<TTransactions>>(
        `/workspaces/${wsId}/subscriptions/${subId}/payment-update`,
      );
      return normalizeTransaction(data as Dto<TTransactions>);
    },
    { enabled: enabled && !!workspace },
  );
}
