import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { updateAccountPermission } from '@/services/api/account';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';

import { SEARCH_RESOURCES_KEY } from '../workspace/resources/useSearchResourcesQuery';

type UserAccountRoleUpdate = {
  workspaceId: string;
  permission: WORKSPACE_MEMBER_PERMISSION;
  accountId: string;
  invited?: boolean;
};

export default function useUpdateAccountPermission({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
} = {}) {
  const queryClient = useQueryClient();

  const { addNotification } = useContext(NotificationsContext);
  return useMutation<
    void,
    Error & { message?: string },
    UserAccountRoleUpdate,
    [QueryKey, TResourceSearchResult[] | undefined][]
  >(
    async ({ workspaceId, permission, accountId }: UserAccountRoleUpdate) => {
      await updateAccountPermission({
        permission,
        workspaceId,
        accountId,
      });
    },
    {
      onMutate: async ({ accountId, permission, workspaceId }) => {
        const key = [SEARCH_RESOURCES_KEY, workspaceId];
        const oldDatas =
          queryClient.getQueriesData<TResourceSearchResult[]>(key);

        oldDatas.forEach(([queryKey, oldData]) => {
          if (!oldData) return;
          const newData = cloneDeep(oldData);
          const oldAccount = newData.find((nd) => nd.accountId === accountId);
          if (!oldAccount) return;
          oldAccount.permission = permission;
          queryClient.setQueryData(queryKey, newData);
        });

        return oldDatas;
      },
      onSuccess: async (_data) => {
        onSuccess?.();
      },
      onError: (error, _variables, oldDatas) => {
        oldDatas?.forEach(([queryKey, oldData]) => {
          queryClient.setQueryData(queryKey, oldData);
        });
        onError?.(error);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
