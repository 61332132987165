import { PropsWithChildren } from 'react';

import LogoHeader from '@/components/LogoHeader';

import styles from './styles.module.css';

export function ErrorScreen({ children }: PropsWithChildren<unknown>) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <LogoHeader />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.textWrapper}>{children}</div>
      </div>
    </div>
  );
}
