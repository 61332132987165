import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';
type SectionProps = {
  title?: string | JSX.Element;
  className?: string;
};

export default function Section(props: PropsWithChildren<SectionProps>) {
  return (
    <div className={classNames(styles.container, props.className ?? '')}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
