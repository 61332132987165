import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';

import { setDefaultOptions } from 'date-fns';
import { xor } from 'lodash';

import {
  TResourceItemList,
  TProjectListWithResources,
  TTimeBlockRange,
} from '@/types/timeline';

import { TimelineProjectsContext } from '@/components/Timelines/TimelineProjects/context';

import ActivePanel from './ActivePanel';
import Blocks from './Blocks';
import Content from './Content';
import ProjectRowContext from './Context';
import type { MouseEventRef } from './Context';
import Cursor from './Cursor';
import Header from './Header';
import NewBlock from './NewBlock';
import styles from './styles.module.css';

type Props = {
  disabled?: boolean;
  resource: TResourceItemList;
  project?: TProjectListWithResources<TResourceItemList>;
};

const ResourceRow = ({
  disabled = false,
  resource,
  project,
  children,
}: PropsWithChildren<Props>) => {
  setDefaultOptions({ weekStartsOn: 1 });
  const [hoverWeek, setHoverWeek] = useState<Date | undefined>(undefined);
  const [newBlock, setNewBlock] = useState<TTimeBlockRange | null>(null);
  const mouseEventRef = useRef<MouseEventRef>({});

  const {
    activeBlockIds,
    activeResourceId,
    setActiveBlockIds,
    setActiveProjectId,
    setActiveResourceId,
  } = useContext(TimelineProjectsContext);

  const setTimerRef = useRef<NodeJS.Timeout | null>();

  const onActiveBlockFn = useCallback(
    ({
      blockId,
      isMulti,
      projectId,
      resourceId,
    }: {
      blockId: string;
      isMulti: boolean;
      projectId: string;
      resourceId: string;
    }) => {
      if (setTimerRef.current) {
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }

      if (activeResourceId && isMulti && activeResourceId !== resourceId)
        return;
      if (isMulti) {
        setActiveBlockIds((prev) => xor(prev, [blockId]));
      } else {
        // 🍟🐷🥓
        // this timeout is to prevent the active block glitching when user move blocks
        setTimerRef.current = setTimeout(() => {
          setActiveBlockIds((prev) => {
            return prev.includes(blockId) ? [] : [blockId];
          });
          setTimerRef?.current && clearTimeout(setTimerRef?.current);
          setTimerRef.current = null;
        }, 10);
      }
      setActiveProjectId(projectId);
      setActiveResourceId(resourceId);
    },
    [
      activeResourceId,
      setActiveBlockIds,
      setActiveResourceId,
      setActiveProjectId,
    ],
  );

  useEffect(() => {
    if (activeBlockIds.length === 0) {
      setActiveProjectId(null);
      setActiveResourceId(null);
    }
  }, [activeBlockIds.length, setActiveProjectId, setActiveResourceId]);

  return (
    <ProjectRowContext.Provider
      value={{
        resource,
        project,
        setNewBlock,
        newBlock,
        hoverWeek,
        setHoverWeek,
        mouseEventRef,
        onActiveBlockFn,
        rowDisabled: disabled,
      }}
    >
      <div className={styles.container}>{children}</div>
    </ProjectRowContext.Provider>
  );
};

ResourceRow.Header = Header;
ResourceRow.Content = Content;
ResourceRow.Cursor = Cursor;
ResourceRow.Blocks = Blocks;
ResourceRow.NewBlock = NewBlock;
ResourceRow.ActivePanel = ActivePanel;

export default ResourceRow;
