import Button from '@components/Button';
import { t } from 'i18next';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';

type Props = {
  onSave: () => void;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  modalKey: string;
  modalTitle: string;
};
export default function ModalUnassign({
  isOpen,
  onClose,
  isLoading,
  onSave,
  title,
  description,
  modalKey,
  modalTitle,
}: Props) {
  return (
    <Modal
      modalKey={modalKey ?? 'modal-unassign-project'}
      isOpen={isOpen}
      onClose={onClose}
      title={modalTitle}
      footer={
        <>
          <Button
            label={t('common:modals.removeProject.primaryCtaLabel')}
            isLoading={isLoading}
            onClick={onSave}
            variant="destructive"
            size="medium"
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <div className={styles.container}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </Modal>
  );
}
