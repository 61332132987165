import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { useWebSocketEvent } from '@/contexts/WebSocketContext';
import { normalizeSubscription } from '@/services/normalizer';
import { WebsocketMessage } from '@/types/websocket';
import { TSubscriptionDto, TUserAccount } from '@/types/workspace';

import { ANALYTICS_EVENTS, useAnalytics } from './useAnalytics';
import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';
import { CAN_DELETE_ACCOUNT_KEY } from '../account/useCanDeleteAccount';
import { GET_INVOICES_KEY } from '../paddle/useGetInvoices';
import { GET_SUBSCRIPTION_KEY } from '../workspace/useGetSubscription';

export default function useSubscriptionWSResponse() {
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(
    (wsId: string) => {
      queryClient.invalidateQueries([GET_INVOICES_KEY, wsId]);
      queryClient.invalidateQueries([CAN_DELETE_ACCOUNT_KEY], {
        exact: false,
      });
      queryClient.invalidateQueries([GET_INVOICES_KEY, wsId]);
    },
    [queryClient],
  );

  const onSubUpdated = useCallback(
    ({ data, workspaceId: wsId }: WebsocketMessage<TSubscriptionDto>) => {
      const opt = {
        subId: data.subscriptionId,
        status: data.status,
        ws: wsId,
      };
      if (data.status === 'canceled') {
        console.log('subscription canceled', wsId);
        const old_account = queryClient.getQueryData<TUserAccount>([
          ACCOUNT_QUERY_KEY,
        ]);
        const account = cloneDeep(old_account);
        if (account?.workspaces?.length) {
          const ws = account.workspaces.find((ws) => ws.id === wsId);
          if (ws) ws.skipTrailing = true;
        }
        queryClient.setQueryData<TUserAccount>([ACCOUNT_QUERY_KEY], account);
        queryClient.setQueryData<TSubscriptionDto | null>(
          [GET_SUBSCRIPTION_KEY, wsId],
          null,
        );
        trackEvent(ANALYTICS_EVENTS.SUBSCRIPTION_CANCELED, wsId, opt);
      } else {
        console.log('subscription updated', wsId);
        queryClient.setQueryData<TSubscriptionDto>(
          [GET_SUBSCRIPTION_KEY, wsId],
          normalizeSubscription(data),
        );
        trackEvent(ANALYTICS_EVENTS.SUBSCRIPTION_UPDATED, wsId, opt);
      }
      invalidateQueries(wsId);
    },
    [invalidateQueries, queryClient, trackEvent],
  );

  const onSubCreated = useCallback(
    ({ data, workspaceId: wsId }: WebsocketMessage<TSubscriptionDto>) => {
      console.log('subscription created', wsId);
      queryClient.setQueryData<TSubscriptionDto>(
        [GET_SUBSCRIPTION_KEY, wsId],
        normalizeSubscription(data),
      );
      trackEvent(ANALYTICS_EVENTS.SUBSCRIPTION_CREATED, wsId);
      invalidateQueries(wsId);
    },
    [invalidateQueries, queryClient, trackEvent],
  );

  useWebSocketEvent('subscription.updated', onSubUpdated);
  useWebSocketEvent('subscription.created', onSubCreated);

  return;
}
