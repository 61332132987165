import { useRef, useState } from 'react';

import { t } from 'i18next';

import useAccountQuery from '@/hooks/account/useAccountQuery';
import useAcceptConsent from '@/hooks/iubenda/useAcceptConsent';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import Button from '../../Button';
import Checkbox from '../../Checkbox';

export default function ModalTermsOfService() {
  // get the current user tos signature, if it exists and is valid close the modal
  // if not, open the modal and force the user to accept the tos
  // on accept, update the user tos signature and close the modal

  const { data } = useAccountQuery({});

  const { mutate, isLoading } = useAcceptConsent();

  const [accepted, setAccepted] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const onSubmitFn = () => {
    mutate(ref?.current?.innerHTML ?? '');
  };

  return (
    !data?.consent && (
      <Modal
        modalKey="modal-terms-of-service"
        ref={ref}
        key={'modal-terms-of-service'}
        isOpen={!data?.consent}
        panelClassName={[styles.panel]}
        title={t('common:modals.tos.title')}
        footer={undefined}
      >
        <div className={styles.container}>
          {/* <div className={styles.avatarWrapper}>
          <img width={40} height={40} src={avatar} alt="logo" />
          <div className={styles.avatarTextWrapper}>
            <span className={styles.avatarName}>Sergio panagia</span>
            <span className={styles.avatarDescr}>Product @ Hellotime</span>
          </div>
        </div> */}
          <div className={styles.textContentWrapper}>
            <div className={styles.textContent}>
              <p>{t('common:modals.tos.description')}</p>
              <ol type="1">
                {Array.from(new Array(4).keys()).map((i) => {
                  return (
                    <li key={i}>{t(`common:modals.tos.list_${i + 1}`)}</li>
                  );
                })}
              </ol>
              <p>{t('common:modals.tos.thanks')}</p>
            </div>
            <Checkbox
              value={accepted}
              name={'consent'}
              onChange={(e) => setAccepted(!!e)}
              label={
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('forms:signUp.terms.label', {
                      POLICY_URL: import.meta.env.VITE_PRIVACY_POLICY_URL,
                      TOS_URL: import.meta.env.VITE_TOS_URL,
                    }),
                  }}
                ></p>
              }
            />
            <Button
              label={t('common:modals.tos.primaryCtaLabel')}
              onClick={onSubmitFn}
              isLoading={isLoading}
              disabled={!accepted}
              size="large"
            />
          </div>
        </div>
      </Modal>
    )
  );
}
