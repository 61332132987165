import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { IconSearch, IconX } from '@tabler/icons-react';
import { isAfter } from 'date-fns';
import { useDebounce } from 'use-hooks';

import { UserContext } from '@/contexts/UserContext';
import useUpdateAccountPermission from '@/hooks/account/useUpdateAccountPermission';
import useUpdateAccountRole from '@/hooks/account/useUpdateAccountRole';
import { ANALYTICS_PAGE_NAME, useAnalytics } from '@/hooks/utils/useAnalytics';
import useSearchResourcesQuery from '@/hooks/workspace/resources/useSearchResourcesQuery';
import useUpdateInvitationPermission from '@/hooks/workspace/resources/useUpdateInvitationPermission';
import { getCssVariable } from '@/services/helpers';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';

import Input from '@/components/Input';
import Section from '@/components/Section';

import MemberManageSection from './MemberSection';
import styles from './styles.module.css';

export default function ModalresourceManage() {
  const [searchValue, setSearchValue] = React.useState<string | undefined>();
  const debouncedValue = useDebounce(searchValue, 300);

  const { trackPage } = useAnalytics();
  const { workspaceId } = useContext(UserContext);

  useEffect(() => {
    if (trackPage && workspaceId)
      trackPage(ANALYTICS_PAGE_NAME.PEOPLE, workspaceId);
  }, [workspaceId, trackPage]);

  const today = useMemo(() => new Date(), []);

  const filterData = useCallback(
    (resources: TResourceSearchResult[]) => {
      return resources?.filter(
        (r) =>
          r.accountId ||
          (r.invitation &&
            r.invitation.expirationDate &&
            isAfter(r.invitation.expirationDate, today)),
      );
    },
    [today],
  );
  const { data: resources, isLoading: _isLoading } = useSearchResourcesQuery({
    limit: 30,
    query:
      debouncedValue && debouncedValue.length > 2 ? debouncedValue : undefined,
    select: filterData,
  });

  const { mutate: mutateRole } = useUpdateAccountRole();
  const { mutate: mutatePermission } = useUpdateAccountPermission();
  const { mutate: mutateInvitationPermission } =
    useUpdateInvitationPermission();

  const onUpdatePermission = useCallback(
    (
      resource: TResourceSearchResult,
      permission: WORKSPACE_MEMBER_PERMISSION,
      workspaceId: string,
    ) => {
      if (!workspaceId) return;
      if (resource.accountId) {
        mutatePermission({
          workspaceId,
          permission,
          accountId: resource.accountId,
        });
      } else if (resource.invitation?.id) {
        mutateInvitationPermission({
          workspaceId,
          permission,
          resourceId: resource.id,
          invitationId: resource.invitation.id,
        });
      }
    },
    [mutateInvitationPermission, mutatePermission],
  );

  return (
    <React.Fragment>
      <Section title="Members">
        <div className={styles.searchWrapper}>
          <Input
            className={styles.searchInput}
            LeadingIcon={<IconSearch size={16} color="#b0b3bb" />}
            TrailingIcon={
              <div className={styles.trailinSearchContainer}>
                {!!searchValue && (
                  <IconX
                    className={styles.clearSearchIcon}
                    size={16}
                    color={getCssVariable('--icon-subdued')}
                    onClick={() => setSearchValue(undefined)}
                  />
                )}
              </div>
            }
            name={'search'}
            value={searchValue ?? ''}
            placeholder="Filter people..."
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </div>
        <MemberManageSection
          workspaceId={workspaceId!}
          resources={resources ?? []}
          onUpdatePermission={(data) => {
            onUpdatePermission(data.resource, data.permission, workspaceId!);
          }}
          onUpdateRole={mutateRole}
        />
      </Section>
    </React.Fragment>
  );
}
