import { LineItem } from '@paddle/paddle-js/types/price-preview/price-preview';
import { useQuery } from '@tanstack/react-query';

import usePaddle from './usePaddle';

type PricePreviewOption = {
  priceId: string | string[];
  countryCode: string;
  quantity?: number;
  enabled?: boolean;
};

const GET_PRICE_PREVIEW = 'get-price-preview';
export default function useGetPricePreview({
  priceId,
  countryCode,
  quantity = 1,
  enabled = true,
}: PricePreviewOption) {
  const { paddle } = usePaddle();
  const KEY: [string, string | string[], string] = [
    GET_PRICE_PREVIEW,
    priceId,
    countryCode,
  ];
  return useQuery<
    LineItem[] | undefined,
    unknown,
    LineItem[] | undefined,
    [string, string | string[], string]
  >(
    KEY,
    async ({ queryKey }) => {
      const [_key, priceId, countryCode] = queryKey;
      const items =
        priceId instanceof Array
          ? priceId.map((p) => ({
              priceId: p,
              quantity,
            }))
          : [{ priceId, quantity }];
      const previews = await paddle?.PricePreview({
        items,
        address: {
          countryCode: countryCode,
        },
      });
      return previews?.data.details.lineItems;
    },
    {
      enabled: !!paddle && enabled,
    },
  );
}
